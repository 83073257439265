import type { PointScalar, PolygonScalar, DateTimeScalar } from './scalars';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeScalar: { input: DateTimeScalar; output: DateTimeScalar; }
  Duration: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Point: { input: PointScalar; output: PointScalar; }
  Polygon: { input: PolygonScalar; output: PolygonScalar; }
}

export enum AccountType {
  Credit = 'CREDIT',
  Sales = 'SALES'
}

export interface ActivateUserMembershipInput {
  channelId: Scalars['Int']['input'];
  membershipId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface AdFilterInput {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  targetAuthorizationTypes?: InputMaybe<Array<TargetAuthorizationType>>;
  targetChannelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  targetRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  targetTaxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface AdMediaInput {
  alt?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mediaQuery: Scalars['String']['input'];
  originalName?: InputMaybe<Scalars['String']['input']>;
  placement: Scalars['String']['input'];
  quality?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface AdMediaObject {
  __typename?: 'AdMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  mediaQuery: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface AdObject {
  __typename?: 'AdObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  media: Array<AdMediaObject>;
  name: Scalars['String']['output'];
  startsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  targetAuthorizationType: TargetAuthorizationType;
  targetChannel: ChannelObject;
  targetChildrenTaxons: Scalars['Boolean']['output'];
  targetRegions: Array<GeoRegionObject>;
  targetTaxons: Array<TaxonObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  urlTarget: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
}

export interface AddProductTaxonInput {
  id: Scalars['Int']['input'];
  taxonIds: Array<Scalars['Int']['input']>;
}

export enum AddressAssignment {
  Billing = 'BILLING',
  Both = 'BOTH',
  Shipping = 'SHIPPING'
}

export interface AddressFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
  userIds?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export interface AddressInput {
  alias?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  complex?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<PointInput>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AddressType>;
}

export interface AddressObject {
  __typename?: 'AddressObject';
  alias?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  complex?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['Point']['output']>;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  geoRegion?: Maybe<GeoRegionObject>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  type: AddressType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum AddressType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export interface AdjustOrderItemInput {
  amount: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
}

export interface AgreementDeclarationFilter {
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface AgreementDeclarationObject {
  __typename?: 'AgreementDeclarationObject';
  agreed: Scalars['Boolean']['output'];
  agreedAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AgreementFilter {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface AgreementObject {
  __typename?: 'AgreementObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  translations: Array<AgreementTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface AgreementTranslationInput {
  label?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  termsUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface AgreementTranslationObject {
  __typename?: 'AgreementTranslationObject';
  agreement: AgreementObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ArchiveUserAddressInput {
  addressId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ArchiveUserPaymentMethodInput {
  userPaymentMethodId: Scalars['Int']['input'];
}

export interface AssignCartAdjustmentInput {
  amount: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface AssignOrderAdjustmentInput {
  amount: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface AuthRoleFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface AuthRoleObject {
  __typename?: 'AuthRoleObject';
  allChannels: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface AuthenticationObject {
  __typename?: 'AuthenticationObject';
  authenticator: Scalars['String']['output'];
  description: Scalars['String']['output'];
  expiresAt: Scalars['DateTimeScalar']['output'];
  issuedAt: Scalars['DateTimeScalar']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  subjectId: Scalars['Int']['output'];
}

export interface BrowseMediaObject {
  __typename?: 'BrowseMediaObject';
  items: Array<MediaObject>;
  nextToken?: Maybe<Scalars['String']['output']>;
  totalItemsCount: Scalars['Int']['output'];
}

export interface BulkOrderEntryObject {
  __typename?: 'BulkOrderEntryObject';
  bulkOrder: BulkOrderObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  order: OrderObject;
  orderTemplateAppliedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  orderTemplateVersion: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface BulkOrderFilterInput {
  authorUserId?: InputMaybe<Scalars['Int']['input']>;
  channelId?: InputMaybe<Scalars['Int']['input']>;
  customerUserId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
}

export interface BulkOrderObject {
  __typename?: 'BulkOrderObject';
  authorUser?: Maybe<UserObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  customerUser?: Maybe<UserObject>;
  entries: Array<BulkOrderEntryObject>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orderSalesStats: Array<OrderSalesStatsObject>;
  orderStateCounts: Array<OrderStateCountsObject>;
  template: OrderTemplateObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface CancelCartPaymentInput {
  paymentId: Scalars['Int']['input'];
}

export interface ChannelFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ChannelLocaleObject {
  __typename?: 'ChannelLocaleObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  locale: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ChannelObject {
  __typename?: 'ChannelObject';
  code: Scalars['String']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  defaultCollectionPoint?: Maybe<CollectionPointObject>;
  defaultCountry: Scalars['String']['output'];
  defaultLocale: Scalars['String']['output'];
  defaultLocaleName?: Maybe<Scalars['String']['output']>;
  defaultTaxCategory?: Maybe<TaxCategoryObject>;
  defaultWarehouse?: Maybe<WarehouseObject>;
  enabled: Scalars['Boolean']['output'];
  enquiryOnly: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  locales: Array<ChannelLocaleObject>;
  name: Scalars['String']['output'];
  regions: Array<GeoRegionObject>;
  rootTaxon?: Maybe<TaxonObject>;
  translations: Array<ChannelTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouses: Array<WarehouseObject>;
}

export interface ChannelTranslationInput {
  contentRef?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name: Scalars['String']['input'];
}

export interface ChannelTranslationObject {
  __typename?: 'ChannelTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta?: Maybe<MetaContentObject>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface CheckoutCartInput {
  orderId: Scalars['Int']['input'];
  orderNeverExpires?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CheckoutRequirementObject {
  __typename?: 'CheckoutRequirementObject';
  code: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export interface ChronoLogicRangeInput {
  date?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  endOffset?: InputMaybe<Scalars['Int']['input']>;
  expand?: InputMaybe<Scalars['Boolean']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  startOffset?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

export interface CloneAdInput {
  id: Scalars['Int']['input'];
  media?: InputMaybe<Scalars['Boolean']['input']>;
  targetContext?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CloneProductInput {
  clone_id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}

export interface ClonePromotionInput {
  cloneActions?: InputMaybe<Scalars['Boolean']['input']>;
  cloneRules?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}

export interface CollectionPointFilterInput {
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface CollectionPointObject {
  __typename?: 'CollectionPointObject';
  address: AddressObject;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  method: ShippingMethodObject;
  name: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ConfigureProductAssociationInput {
  associatedProducts: Array<ProductAssociatedProductInput>;
  associationId: Scalars['Int']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['Int']['input'];
  sourceMainTaxonEnable?: InputMaybe<Scalars['Boolean']['input']>;
  sourceTaxonId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ContentProviderObject {
  __typename?: 'ContentProviderObject';
  contentTypes: Array<ContentProviderTypeObject>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ContentProviderTypeObject {
  __typename?: 'ContentProviderTypeObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ContentRefObject {
  __typename?: 'ContentRefObject';
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  ref: Scalars['String']['output'];
}

export interface ContentSearchInput {
  contentType: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  search: Scalars['String']['input'];
}

export interface CoordinatesFilterInput {
  east: Scalars['Float']['input'];
  north: Scalars['Float']['input'];
  south: Scalars['Float']['input'];
  west: Scalars['Float']['input'];
}

export interface CouponPromotionEligibilityObject {
  __typename?: 'CouponPromotionEligibilityObject';
  isEligible: Scalars['Boolean']['output'];
  promotionId: Scalars['Int']['output'];
  ruleExplanation?: Maybe<Scalars['String']['output']>;
  uneligibleReasonCode?: Maybe<Scalars['String']['output']>;
}

export enum CouponType {
  Bulk = 'BULK',
  Single = 'SINGLE'
}

export interface CreateAdInput {
  enabled: Scalars['Boolean']['input'];
  endsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  targetChannelId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
}

export interface CreateAgreementInput {
  channelIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
}

export interface CreateAuthRoleInput {
  allChannels: Scalars['Boolean']['input'];
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface CreateBulkOrderInput {
  customerUserId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  templateId: Scalars['Int']['input'];
}

export interface CreateBulkOrderOrderInput {
  address?: InputMaybe<AddressInput>;
  id: Scalars['Int']['input'];
}

export interface CreateCartInput {
  address?: InputMaybe<AddressInput>;
  addressAssignment?: InputMaybe<AddressAssignment>;
  channelId: Scalars['Int']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  skipAgreements?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateChannelInput {
  code: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  defaultCollectionPointId?: InputMaybe<Scalars['Int']['input']>;
  defaultCountry: Scalars['String']['input'];
  defaultLocale: Scalars['String']['input'];
  defaultTaxCategoryId: Scalars['Int']['input'];
  defaultWarehouseId?: InputMaybe<Scalars['Int']['input']>;
  enabled: Scalars['Boolean']['input'];
  host?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rootTaxonId?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateCollectionPointInput {
  address: AddressInput;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  methodId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  providerConfig?: InputMaybe<Scalars['JSON']['input']>;
}

export interface CreateGeoRegionDefinitionInput {
  boundary?: InputMaybe<PolygonInput>;
  center?: InputMaybe<PointInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  geoRegionId: Scalars['Int']['input'];
  include: Scalars['Boolean']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  type: GeoRegionDefinitionType;
}

export interface CreateGeoRegionInput {
  channelIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
  primaryChannelId: Scalars['Int']['input'];
}

export interface CreateManualPaymentInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
  reference: Scalars['String']['input'];
}

export interface CreateMemberInput {
  channelCode?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['Int']['input']>;
  membershipId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface CreateMembershipInput {
  channelIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
}

export interface CreateOrderIncidentCategoryInput {
  name: Scalars['String']['input'];
}

export interface CreateOrderIncidentInput {
  orderId: Scalars['Int']['input'];
}

export interface CreateOrderShipmentParcelInput {
  dimensions?: InputMaybe<DimensionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
  parcelOptionId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateOrderTagInput {
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
}

export interface CreateOrderTemplateInput {
  channelId: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CreateParcelOptionInput {
  dimensions?: InputMaybe<DimensionsInput>;
  name: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreatePaymentMethodInput {
  channelCodes: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  providerConfig: Scalars['JSON']['input'];
}

export interface CreateProductAssociationInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  recommendationModel?: InputMaybe<Scalars['String']['input']>;
  sourceMainTaxonEnable?: InputMaybe<Scalars['Boolean']['input']>;
  sourceTaxonId?: InputMaybe<Scalars['Int']['input']>;
  targetMainTaxonEnable?: InputMaybe<Scalars['Boolean']['input']>;
  targetTaxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  targets?: InputMaybe<Array<ProductAssociationTarget>>;
  testCase?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<ProductAssociationTranslationInput>>;
}

export interface CreateProductAttributeInput {
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  translations: Array<ProductAttributeTranslationInput>;
  type: ProductAttributeType;
}

export interface CreateProductInput {
  mainTaxonId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  optionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  variation: ProductVariation;
}

export interface CreateProductOptionInput {
  name: Scalars['String']['input'];
  translations?: InputMaybe<Array<ProductOptionTranslationInput>>;
  values?: InputMaybe<Array<ProductOptionValueInput>>;
}

export interface CreatePromotionInput {
  actions?: InputMaybe<Array<PromotionActionInput>>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  couponType?: CouponType;
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  rules?: InputMaybe<Array<PromotionRuleInput>>;
}

export interface CreateShipmentParcelInput {
  dimensions?: InputMaybe<DimensionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parcelOptionId?: InputMaybe<Scalars['Int']['input']>;
  shipmentId: Scalars['Int']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateShippingCategoryInput {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface CreateShippingMethodInput {
  categoryComparison?: InputMaybe<ShippingCategoryComparison>;
  channelIds: Array<Scalars['Int']['input']>;
  collectionRequired: Scalars['Boolean']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  costCalculator: Scalars['String']['input'];
  costCalculatorConfig?: InputMaybe<Scalars['JSON']['input']>;
  costProductVariantId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  groupDestination: Scalars['Boolean']['input'];
  labelGenerator: Scalars['String']['input'];
  labelGeneratorConfig?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  provider: Scalars['String']['input'];
  providerConfig?: InputMaybe<Scalars['JSON']['input']>;
  quoteValidityDuration?: InputMaybe<Scalars['Duration']['input']>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  slotRequired: Scalars['Boolean']['input'];
  stockType?: InputMaybe<StockType>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['Int']['input'];
}

export interface CreateShippingScheduleInput {
  capacity: Scalars['Int']['input'];
  daysOfWeek?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Duration']['input'];
  enabled: Scalars['Boolean']['input'];
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  lagTime?: InputMaybe<Scalars['Duration']['input']>;
  leadTime?: InputMaybe<Scalars['Duration']['input']>;
  methodId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  priority?: Scalars['Int']['input'];
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  startTime: Scalars['String']['input'];
}

export interface CreateShippingSlotInput {
  capacity: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  endAt: Scalars['DateTimeScalar']['input'];
  lagTime?: InputMaybe<Scalars['Duration']['input']>;
  leadTime?: InputMaybe<Scalars['Duration']['input']>;
  methodId: Scalars['Int']['input'];
  priority?: Scalars['Int']['input'];
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startAt: Scalars['DateTimeScalar']['input'];
}

export interface CreateStockCategoryInput {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface CreateStockItemComponentInput {
  componentItemId: Scalars['Int']['input'];
  componentQuantity: Scalars['Int']['input'];
  parentItemId: Scalars['Int']['input'];
}

export interface CreateStockItemInput {
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  productVariantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sku: Scalars['String']['input'];
  stockCategoryId: Scalars['Int']['input'];
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<StockType>;
  unit?: InputMaybe<Scalars['String']['input']>;
  warehouseOptions?: InputMaybe<Array<StockItemWarehouseOptionInput>>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateStockLotInput {
  availableAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  itemId: Scalars['Int']['input'];
  priority?: Scalars['Int']['input'];
  quantity?: Scalars['Int']['input'];
  tracked?: InputMaybe<Scalars['Boolean']['input']>;
  warehouseId: Scalars['Int']['input'];
}

export interface CreateSubscriberInput {
  billingAddressId?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  locale: Scalars['String']['input'];
  shippingAddressId?: InputMaybe<Scalars['Int']['input']>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  state?: InputMaybe<SubscriberState>;
  subscriptionVariantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  userPaymentMethodId?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateSubscriberTagInput {
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
}

export interface CreateSubscriptionInput {
  name: Scalars['String']['input'];
}

export interface CreateTaxCategoryInput {
  name: Scalars['String']['input'];
}

export interface CreateTaxRateInput {
  amount: Scalars['Int']['input'];
  categoryId: Scalars['Int']['input'];
  channelId: Scalars['Int']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  inclusive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateTaxonInput {
  badgeIconUrl?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateUserAddressInput {
  address: AddressInput;
  setAsDefaultBillingAddress?: Scalars['Boolean']['input'];
  setAsDefaultShippingAddress?: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
}

export interface CreateUserInput {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateVoucherInput {
  orderItemUnitId?: InputMaybe<Scalars['Int']['input']>;
  programId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
  value: Scalars['Int']['input'];
}

export interface CreateVoucherProgramInput {
  currency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productVariantIds: Array<Scalars['Int']['input']>;
  value: Scalars['Int']['input'];
  voucherProviderId: Scalars['String']['input'];
}

export interface CreateWalletInput {
  balance?: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
  walletType?: WalletType;
}

export interface CreateWarehouseInput {
  address?: InputMaybe<AddressInput>;
  channelIds: Array<Scalars['Int']['input']>;
  code: Scalars['String']['input'];
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  timezone: Scalars['String']['input'];
}

export interface CredentialsInput {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface CreditWalletInput {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['Int']['input']>;
  walletId: Scalars['Int']['input'];
}

export interface DateRangeFilterInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  expand?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
}

export interface DeactivateUserMembershipInput {
  channelId: Scalars['Int']['input'];
  membershipId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface DebitWalletInput {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['Int']['input']>;
  walletId: Scalars['Int']['input'];
}

export interface DebitWalletPaymentInput {
  description: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface DeleteGlobalVariableInput {
  key: Scalars['String']['input'];
}

export interface DeleteUserVariableInput {
  key: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
}

export interface DimensionsInput {
  height: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
}

export interface DimensionsObject {
  __typename?: 'DimensionsObject';
  height: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
}

export interface EmailResultObject {
  __typename?: 'EmailResultObject';
  recipientEmail: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  sent: Scalars['Boolean']['output'];
}

export interface EnquireCartInput {
  orderId: Scalars['Int']['input'];
}

export interface ForgotPasswordInput {
  email: Scalars['String']['input'];
}

export interface ForgotPasswordObject {
  __typename?: 'ForgotPasswordObject';
  email: Scalars['String']['output'];
}

export interface FulfilmentIntervalObject {
  __typename?: 'FulfilmentIntervalObject';
  id: Scalars['String']['output'];
  interval: IntervalObject;
  isAvailable: Scalars['Boolean']['output'];
  slotAvailability?: Maybe<ShippingSlotAvailabilityObject>;
  stockAvailability?: Maybe<StockAvailabilityIntervalObject>;
  unavailableReason?: Maybe<Scalars['String']['output']>;
}

export interface FulfilmentObject {
  __typename?: 'FulfilmentObject';
  allocatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  cancelledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  date?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  order: OrderObject;
  packedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  reservedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: FulfilmentState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse?: Maybe<WarehouseObject>;
}

export enum FulfilmentState {
  Allocated = 'ALLOCATED',
  Cancelled = 'CANCELLED',
  New = 'NEW',
  Packed = 'PACKED',
  Reserved = 'RESERVED',
  Unallocated = 'UNALLOCATED'
}

export interface GeoRegionDefinitionFilterInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  geoRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface GeoRegionDefinitionObject {
  __typename?: 'GeoRegionDefinitionObject';
  boundary?: Maybe<Scalars['Polygon']['output']>;
  center?: Maybe<Scalars['Point']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  include: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  region: GeoRegionObject;
  suburb?: Maybe<Scalars['String']['output']>;
  type: GeoRegionDefinitionType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface GeoRegionDefinitionScoreObject {
  __typename?: 'GeoRegionDefinitionScoreObject';
  definition: GeoRegionDefinitionObject;
  score: Scalars['Float']['output'];
}

export enum GeoRegionDefinitionType {
  Address = 'ADDRESS',
  Boundary = 'BOUNDARY',
  Postcode = 'POSTCODE'
}

export interface GeoRegionFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface GeoRegionMatchResultObject {
  __typename?: 'GeoRegionMatchResultObject';
  definition: GeoRegionDefinitionObject;
  matchedDefinitions?: Maybe<Array<GeoRegionDefinitionScoreObject>>;
  region: GeoRegionObject;
  score: Scalars['Float']['output'];
}

export interface GeoRegionObject {
  __typename?: 'GeoRegionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  definitions: Array<GeoRegionDefinitionObject>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<GeoRegionObject>;
  primaryChannel: ChannelObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface IntervalObject {
  __typename?: 'IntervalObject';
  end: Scalars['DateTimeScalar']['output'];
  start: Scalars['DateTimeScalar']['output'];
}

export interface LoginObject {
  __typename?: 'LoginObject';
  expiresAt: Scalars['DateTimeScalar']['output'];
  issuedAt: Scalars['DateTimeScalar']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface LoginOptionsInput {
  duration?: InputMaybe<Scalars['String']['input']>;
}

export interface MediaFolderObject {
  __typename?: 'MediaFolderObject';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
}

export interface MediaObject {
  __typename?: 'MediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE'
}

export interface MediaUploadInput {
  fields?: InputMaybe<Scalars['JSON']['input']>;
  mediaType: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
}

export interface MediaUploadUrlObject {
  __typename?: 'MediaUploadUrlObject';
  fields?: Maybe<Scalars['JSON']['output']>;
  postUrl: Scalars['String']['output'];
}

export interface MemberFilterInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  membershipIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<MemberState>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export enum MemberIntervalState {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export interface MemberObject {
  __typename?: 'MemberObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  deactivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  expiryDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  firstActivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  intervalState: MemberIntervalState;
  isActive: Scalars['Boolean']['output'];
  membership: MembershipObject;
  pointBalance: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: MemberState;
  subscriber?: Maybe<SubscriberObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export enum MemberState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  New = 'NEW'
}

export interface MembershipFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface MembershipObject {
  __typename?: 'MembershipObject';
  activationUrl?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  members: Array<MemberObject>;
  name: Scalars['String']['output'];
  subscriptionBenefits: Array<SubscriptionMembershipBenefitObject>;
  taxons: Array<TaxonObject>;
  translations: Array<MembershipTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface MembershipObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface MembershipTranslationInput {
  contentRef?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  renewalMessage?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  termsUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface MembershipTranslationObject {
  __typename?: 'MembershipTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  membership: MembershipObject;
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  renewalMessage?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface MetaContentInput {
  description?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface MetaContentObject {
  __typename?: 'MetaContentObject';
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  activateMember: MemberObject;
  activateSubscriber: SubscriberObject;
  activateUserMembership: MemberObject;
  addOrderTags: OrderObject;
  addProductRegions: Array<ProductObject>;
  addProductTaxon: ProductObject;
  addProductWishlistItem: ProductWishlistItemObject;
  addSubscriberTags: SubscriberObject;
  adjustOrderItem: OrderObject;
  adjustStockLot: StockLotObject;
  allocateOrderFulfilment: OrderObject;
  anonymizeUser: UserObject;
  applyAssociationRecommendationModel: Scalars['Int']['output'];
  applyProductAssociationRecommendationModel: Scalars['Int']['output'];
  /** Approve an order incident for resolution */
  approveOrderIncident: OrderIncidentObject;
  archiveAgreement: AgreementObject;
  archiveAuthRole: AuthRoleObject;
  archiveGeoRegion: GeoRegionObject;
  archiveMembership: MembershipObject;
  archiveOrder: OrderObject;
  archiveOrderNote: OrderNoteObject;
  archiveParcelOption: ParcelOptionObject;
  archiveProduct: ProductObject;
  archiveProductAttribute: ProductAttributeObject;
  archiveProductOption: ProductOptionObject;
  archivePromotion: PromotionObject;
  archiveSubscription: SubscriptionObject;
  archiveSubscriptionMembershipBenefit: SubscriptionMembershipBenefitObject;
  archiveTaxon: TaxonObject;
  archiveUser: UserObject;
  archiveUserAddress: UserObject;
  archiveUserPaymentMethod: UserPaymentMethodObject;
  archiveVoucherProgram: VoucherProgramObject;
  assignCartAddress: OrderObject;
  assignCartAdjustment: OrderObject;
  assignCartCloneAddress: OrderObject;
  assignCartCouponCode: OrderObject;
  assignCartCustomer: OrderObject;
  assignCartReferrerCode: OrderObject;
  assignOrderAddress: OrderObject;
  assignOrderAdjustment: OrderObject;
  assignOrderCustomer: OrderObject;
  assignShipmentServiceOption: ShipmentObject;
  cancelCartPayment: PaymentObject;
  cancelOrder: OrderObject;
  cancelOrderFulfilment: OrderObject;
  cancelOrderShipment: OrderObject;
  cancelVoucher: VoucherObject;
  channelSetDefault: ChannelObject;
  checkoutCart: OrderObject;
  cloneAd: AdObject;
  cloneProduct: ProductObject;
  clonePromotion: PromotionObject;
  configureProductAssociation: ProductAssociationConfigObject;
  createAd: AdObject;
  createAgreement: AgreementObject;
  createAuthRole: AuthRoleObject;
  createBulkOrder: BulkOrderObject;
  createBulkOrderOrder: OrderObject;
  createCart: OrderObject;
  createChannel: ChannelObject;
  createCollectionPoint: CollectionPointObject;
  createGeoRegion: GeoRegionObject;
  createGeoRegionDefinition: GeoRegionDefinitionObject;
  /** Create a replacement order for the incident */
  createIncidentReplacementOrder: OrderIncidentObject;
  createManualPayment: PaymentObject;
  createMember: MemberObject;
  createMembership: MembershipObject;
  /** Create a new order incident */
  createOrderIncident: OrderIncidentObject;
  /** Create a new order incident category */
  createOrderIncidentCategory: OrderIncidentCategoryObject;
  createOrderNote: OrderNoteObject;
  createOrderShipmentParcel: ShipmentParcelObject;
  createOrderTag: OrderTagObject;
  createOrderTemplate: OrderTemplateObject;
  createParcelOption: ParcelOptionObject;
  createPaymentMethod: PaymentMethodObject;
  createProduct: ProductObject;
  createProductAssociation: ProductAssociationObject;
  createProductAttribute: ProductAttributeObject;
  createProductOption: ProductOptionObject;
  createPromotion: PromotionObject;
  createShipmentParcel: ShipmentParcelObject;
  createShippingCategory: ShippingCategoryObject;
  createShippingMethod: ShippingMethodObject;
  createShippingSchedule: ShippingScheduleObject;
  createShippingSlot: ShippingSlotObject;
  createStockCategory: StockCategoryObject;
  createStockItem: StockItemObject;
  createStockItemComponent: StockItemComponentObject;
  createStockLot: StockLotObject;
  createStockSchedule: StockScheduleObject;
  createSubscriber: SubscriberObject;
  createSubscriberTag: SubscriberTagObject;
  createSubscription: SubscriptionObject;
  createSubscriptionMembershipBenefit: SubscriptionMembershipBenefitObject;
  createSubscriptionRun: SubscriptionRunObject;
  createSubscriptionVariant: SubscriptionVariantObject;
  createSubscriptionVariantProductQuantity: SubscriptionVariantProductQuantityObject;
  createTaxCategory: TaxCategoryObject;
  createTaxRate: TaxRateObject;
  createTaxon: TaxonObject;
  createUser: UserObject;
  createUserAddress: UserObject;
  createVoucher: VoucherObject;
  createVoucherProgram: VoucherProgramObject;
  createWallet: WalletObject;
  createWarehouse: WarehouseObject;
  creditWallet: WalletTransactionObject;
  deactivateMember: MemberObject;
  deactivateUserMembership?: Maybe<MemberObject>;
  debitWallet: WalletTransactionObject;
  debitWalletPayment: WalletTransactionObject;
  deleteGeoRegionDefinition: Scalars['Float']['output'];
  deleteGlobalVariable: VariableDeleteObject;
  deleteStockItemComponent: Scalars['Int']['output'];
  deleteUserVariable: VariableDeleteObject;
  deliverOrderShipment: OrderObject;
  /** Deny an order incident for resolution */
  denyOrderIncident: OrderIncidentObject;
  enquireCart: OrderObject;
  expireOrder: OrderObject;
  exportMemberReport: PrintResultObject;
  exportStockItemQuantities: PrintResultObject;
  /** Fail an order incident resolution */
  failOrderIncident: OrderIncidentObject;
  forgotPassword: ForgotPasswordObject;
  generatePromotionBulkCouponCodes: Array<Scalars['String']['output']>;
  generateSubscriptionEntries: SubscriptionRunGenerateEntriesResult;
  loginUser: LoginObject;
  maintainOrders: Scalars['Boolean']['output'];
  manifestStockLot: StockLotObject;
  markOrderShipmentLabelled: OrderObject;
  packOrderFulfilment: OrderObject;
  pauseSubscriber: SubscriberObject;
  /** Set the position of an order incident */
  positionOrderIncidentCategory: OrderIncidentCategoryObject;
  positionProductAssociation: ProductAssociationObject;
  printDpdSpreadsheet: PrintResultObject;
  printOrderGiftMessage: PrintResultObject;
  printOrderManifest: PrintResultObject;
  printOrderManifestSpreadsheet: PrintResultObject;
  printOrderManifestV2: PrintResultObject;
  printOrderReport: PrintResultObject;
  printOrderShipmentLabels: PrintResultObject;
  printOrderTaxInvoice: PrintResultObject;
  printPackFleetSpreadsheet: PrintResultObject;
  printRouteManifest: PrintResultObject;
  printRouteSummary: PrintResultObject;
  printRoyalMailReport: PrintResultObject;
  printShipmentLabels: PrintResultObject;
  printShipmentOrderGiftMessage: PrintResultObject;
  printShipmentOrderManifest: PrintResultObject;
  printStockItemReport: PrintResultObject;
  printStockLotAdjustment: PrintResultObject;
  printSubscriberReport: PrintResultObject;
  printVouchersManifest: PrintResultObject;
  processBulkOrder: BulkOrderObject;
  processCart: OrderObject;
  processOrder: OrderObject;
  /** Process an order incident resolution */
  processOrderIncident: OrderIncidentObject;
  processSubscriptionEntries: SubscriptionRunProcessEntriesResult;
  processSubscriptionEntriesPayment: SubscriptionRunProcessPaymentsResult;
  /** Publish an order incident for approval */
  publishOrderIncident: OrderIncidentObject;
  queueSubscriptionRunProcessEntries: SubscriptionRunObject;
  queueSubscriptionRunProcessPayments: SubscriptionRunObject;
  reconOrderShipment: OrderObject;
  reconShipment: ShipmentObject;
  recreateCart: OrderObject;
  redeemCartPayment: PaymentObject;
  refreshUser: LoginObject;
  refundCartPayment: PaymentObject;
  refundOrderToWallet: WalletTransactionObject;
  registerUser: LoginObject;
  removeCartItem: OrderObject;
  /** Remove the replacement order for the incident */
  removeIncidentReplacementOrder: OrderIncidentObject;
  removeOrderItem: OrderObject;
  removeOrderTags: OrderObject;
  removeOrderTemplateItem: OrderTemplateObject;
  removeProductRegions: Array<ProductObject>;
  removeProductTaxon: ProductObject;
  removeProductWishlistItem?: Maybe<ProductWishlistItemObject>;
  removeShipmentParcel: Scalars['Int']['output'];
  removeSubscriberTags: SubscriberObject;
  reportProductAvailability: ReportObject;
  reportProducts: ReportObject;
  requestCartPayment: PaymentObject;
  requestNewCartPayment: PaymentObject;
  requestOrderShipmentSlot: OrderObject;
  rescheduleOrderShipment: OrderObject;
  rescheduleShipment: ShipmentObject;
  reserveOrderFulfilment: OrderObject;
  resetPassword: ForgotPasswordObject;
  resetUserPassword: UserObject;
  sendAbandonedCartEmail: EmailResultObject;
  sendEntryNotification: Array<NotificationLogObject>;
  sendMembershipSignUpEmail: EmailResultObject;
  sendOrderEnquiryEmail: EmailResultObject;
  sendOrderRecievedEmail: EmailResultObject;
  sendOrdersShippedEmail: EmailResultObject;
  sendPasswordResetEmail: EmailResultObject;
  sendRequestPaymentEmail: EmailResultObject;
  sendSubscriberEmail: Array<NotificationLogObject>;
  sendSubscriptionEntrySms: Array<NotificationLogObject>;
  sendVoucherEmail: EmailResultObject;
  setGlobalVariable: VariableObject;
  setOrderShipmentFixedCost: OrderObject;
  setOrderTemplateItem: OrderTemplateObject;
  setProductPosition?: Maybe<ProductPositionObject>;
  setUserVariable: VariableObject;
  shipOrderShipment: OrderObject;
  shipmentPrintOrderCourierSpreadsheet: PrintResultObject;
  shipmentPrintPackFleetSpreadsheet: PrintResultObject;
  shipmentPrintRoyalMailReport: PrintResultObject;
  shipmentsPrintRouteManifest: PrintResultObject;
  shipmentsPrintRouteSummary: PrintResultObject;
  shopActivateMember: ShopMemberObject;
  shopActivateSubscriber: ShopSubscriberObject;
  shopAddWishlistItem: ShopProductWishlistItemObject;
  shopArchiveUserPaymentMethod: ShopMutationResult;
  shopCancelCartPayment: ShopPaymentObject;
  shopCancelOrder: ShopOrderObject;
  shopCheckoutOrder: ShopOrderObject;
  shopCreateCart: ShopCartCreateResultObject;
  shopCreateCartPayment: ShopPaymentObject;
  shopCreateCartToken: ShopOrderAuthenticationObject;
  shopCreateMember: ShopMemberObject;
  shopCreateNewCartPayment: ShopPaymentObject;
  shopCreateSubscriber: ShopSubscriberObject;
  shopCreateUserAddress: ShopAddressObject;
  shopCreateUserPaymentMethod: ShopUserPaymentMethodObject;
  shopCreateWalletPayment: ShopWalletTransactionObject;
  shopDeactivateMember: ShopMemberObject;
  shopDeleteCartItem: ShopOrderObject;
  shopDeleteUserAddress: ShopMutationResult;
  shopEnquireOrder: ShopOrderObject;
  shopExportOrderInvoice: ShopExportOrderInvoiceObject;
  shopPauseSubscriber: ShopSubscriberObject;
  shopProcessCart: ShopOrderObject;
  shopRecreateCart: ShopCartCreateResultObject;
  shopRedeemCartPayment: ShopPaymentObject;
  shopRefreshCartToken: ShopOrderAuthenticationObject;
  /** Returns the productId */
  shopRemoveWishlistItem: Scalars['Int']['output'];
  shopRequestOrderShipmentSlot: ShopShipmentObject;
  shopSubscriberDeclaration: Array<ShopSubscriberAgreementDeclarationObject>;
  shopUpdateCartAddress: ShopOrderObject;
  shopUpdateCartCollectionPoint: ShopOrderObject;
  shopUpdateCartCoupon: ShopOrderObject;
  shopUpdateCartCustomer: ShopOrderObject;
  shopUpdateCartGiftOptions: ShopOrderObject;
  shopUpdateCartInstructions: ShopOrderObject;
  shopUpdateCartItem: ShopOrderObject;
  shopUpdateCartItems: ShopOrderObject;
  shopUpdateCartReferrer: ShopOrderObject;
  shopUpdateSubscriberAddress: ShopSubscriberObject;
  shopUpdateSubscriberPaymentMethod: ShopSubscriberObject;
  shopUpdateUser: ShopUserObject;
  shopUpdateUserAddress: ShopAddressObject;
  shopUpdateUserPassword: ShopUserObject;
  shopUserDeclaration: Array<ShopUserAgreementObject>;
  submitOrderShipmentWaybill: OrderObject;
  syncMediaToContent: Scalars['Boolean']['output'];
  transitionShipment: ShipmentObject;
  unallocateOrderFulfilment: OrderObject;
  unlockSubscriptionRunProcessing: SubscriptionRunObject;
  unpackOrderFulfilment: OrderObject;
  /** Unpublish an order incident from pending approval */
  unpublishOrderIncident: OrderIncidentObject;
  updateAd: AdObject;
  updateAgreement: AgreementObject;
  updateAuthRole: AuthRoleObject;
  updateBulkOrder: BulkOrderObject;
  updateCartCollectionPoint: OrderObject;
  updateCartGiftOptions: OrderObject;
  updateCartInstructions: OrderObject;
  updateCartItem: OrderObject;
  updateChannel: ChannelObject;
  updateCollectionPoint: CollectionPointObject;
  updateGeoRegion: GeoRegionObject;
  updateGeoRegionDefinition: GeoRegionDefinitionObject;
  updateManyProducts: Array<ProductObject>;
  updateMember: MemberObject;
  updateMembership: MembershipObject;
  updateOrderGiftOptions: OrderObject;
  /** Update an existing order incident */
  updateOrderIncident: OrderIncidentObject;
  /** Update an existing order incident */
  updateOrderIncidentCategory: OrderIncidentCategoryObject;
  updateOrderInstructions: OrderObject;
  updateOrderItem: OrderObject;
  updateOrderShipment: OrderObject;
  updateOrderTag: OrderTagObject;
  updateParcelOption: ParcelOptionObject;
  updatePaymentMethod: PaymentMethodObject;
  updatePaymentMethodChannelEnabled: PaymentMethodObject;
  updateProduct: ProductObject;
  updateProductAssociation: ProductAssociationObject;
  updateProductAttribute: ProductAttributeObject;
  updateProductKeywords: ProductObject;
  updateProductOption: ProductOptionObject;
  updatePromotion: PromotionObject;
  updatePromotionEnabled: PromotionObject;
  updateShipmentParcel: ShipmentParcelObject;
  updateShippingCategory: ShippingCategoryObject;
  updateShippingMethod: ShippingMethodObject;
  updateShippingMethodChannelEnabled: ShippingMethodObject;
  updateShippingSchedule: ShippingScheduleObject;
  updateShippingScheduleSlots: ShippingScheduleObject;
  updateShippingSlot: ShippingSlotObject;
  updateStockCategory: StockCategoryObject;
  updateStockItem: StockItemObject;
  updateStockItemCategory: StockItemObject;
  updateStockItemComponent: StockItemComponentObject;
  updateStockItemTags: StockItemObject;
  updateStockLot: StockLotObject;
  updateStockSchedule: StockScheduleObject;
  updateStockScheduleEnabled: StockScheduleObject;
  updateSubscriber: SubscriberObject;
  updateSubscriberAddress: SubscriberObject;
  updateSubscriberTag: SubscriberTagObject;
  updateSubscription: SubscriptionObject;
  updateSubscriptionRun: SubscriptionRunObject;
  updateSubscriptionVariant: SubscriptionVariantObject;
  updateSubscriptionVariantProductQuantity: SubscriptionVariantProductQuantityObject;
  updateTaxCategory: TaxCategoryObject;
  updateTaxRate: TaxRateObject;
  updateTaxon: TaxonObject;
  updateUser: UserObject;
  updateUserAddress: UserObject;
  updateUserRoles: UserObject;
  updateVoucherProgram: VoucherProgramObject;
  updateWarehouse: WarehouseObject;
  userToken: UserObject;
}


export interface MutationActivateMemberArgs {
  id: Scalars['Int']['input'];
}


export interface MutationActivateSubscriberArgs {
  id: Scalars['Int']['input'];
}


export interface MutationActivateUserMembershipArgs {
  input: ActivateUserMembershipInput;
}


export interface MutationAddOrderTagsArgs {
  input: OrderTagInput;
}


export interface MutationAddProductRegionsArgs {
  data: UpdateProductRegionsInput;
}


export interface MutationAddProductTaxonArgs {
  data: AddProductTaxonInput;
}


export interface MutationAddProductWishlistItemArgs {
  input: ProductWishlistItemInput;
}


export interface MutationAddSubscriberTagsArgs {
  input: SubscriberTagInput;
}


export interface MutationAdjustOrderItemArgs {
  data: AdjustOrderItemInput;
}


export interface MutationAdjustStockLotArgs {
  adjustment: Scalars['Int']['input'];
  ref: Scalars['String']['input'];
}


export interface MutationAllocateOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationAnonymizeUserArgs {
  id: Scalars['Int']['input'];
}


export interface MutationApplyAssociationRecommendationModelArgs {
  id: Scalars['Int']['input'];
}


export interface MutationApplyProductAssociationRecommendationModelArgs {
  associationId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
}


export interface MutationApproveOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveAgreementArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveAuthRoleArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveGeoRegionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveMembershipArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveOrderArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveOrderNoteArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveParcelOptionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveProductArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveProductAttributeArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveProductOptionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchivePromotionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveSubscriptionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveSubscriptionMembershipBenefitArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveTaxonArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveUserArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveUserAddressArgs {
  input: ArchiveUserAddressInput;
}


export interface MutationArchiveUserPaymentMethodArgs {
  id: Scalars['Int']['input'];
}


export interface MutationArchiveVoucherProgramArgs {
  id: Scalars['Int']['input'];
}


export interface MutationAssignCartAddressArgs {
  data: UpdateCartAddressInput;
}


export interface MutationAssignCartAdjustmentArgs {
  data: AssignCartAdjustmentInput;
}


export interface MutationAssignCartCloneAddressArgs {
  data: UpdateCartCloneAddressInput;
}


export interface MutationAssignCartCouponCodeArgs {
  data: UpdateCartCouponCodeInput;
}


export interface MutationAssignCartCustomerArgs {
  data: UpdateCartCustomerInput;
}


export interface MutationAssignCartReferrerCodeArgs {
  data: UpdateCartReferrerCodeInput;
}


export interface MutationAssignOrderAddressArgs {
  data: UpdateOrderAddressInput;
}


export interface MutationAssignOrderAdjustmentArgs {
  data: AssignOrderAdjustmentInput;
}


export interface MutationAssignOrderCustomerArgs {
  data: UpdateOrderCustomerInput;
}


export interface MutationAssignShipmentServiceOptionArgs {
  input: UpdateShipmentServiceOptionInput;
}


export interface MutationCancelCartPaymentArgs {
  input: CancelCartPaymentInput;
}


export interface MutationCancelOrderArgs {
  id: Scalars['Int']['input'];
}


export interface MutationCancelOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationCancelOrderShipmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationCancelVoucherArgs {
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}


export interface MutationChannelSetDefaultArgs {
  id: Scalars['Int']['input'];
}


export interface MutationCheckoutCartArgs {
  data: CheckoutCartInput;
}


export interface MutationCloneAdArgs {
  input: CloneAdInput;
}


export interface MutationCloneProductArgs {
  data: CloneProductInput;
}


export interface MutationClonePromotionArgs {
  data: ClonePromotionInput;
}


export interface MutationConfigureProductAssociationArgs {
  input: ConfigureProductAssociationInput;
}


export interface MutationCreateAdArgs {
  input: CreateAdInput;
}


export interface MutationCreateAgreementArgs {
  input: CreateAgreementInput;
}


export interface MutationCreateAuthRoleArgs {
  input: CreateAuthRoleInput;
}


export interface MutationCreateBulkOrderArgs {
  input: CreateBulkOrderInput;
}


export interface MutationCreateBulkOrderOrderArgs {
  input: CreateBulkOrderOrderInput;
}


export interface MutationCreateCartArgs {
  data: CreateCartInput;
}


export interface MutationCreateChannelArgs {
  data: CreateChannelInput;
}


export interface MutationCreateCollectionPointArgs {
  data: CreateCollectionPointInput;
}


export interface MutationCreateGeoRegionArgs {
  data: CreateGeoRegionInput;
}


export interface MutationCreateGeoRegionDefinitionArgs {
  data: CreateGeoRegionDefinitionInput;
}


export interface MutationCreateIncidentReplacementOrderArgs {
  id: Scalars['Int']['input'];
}


export interface MutationCreateManualPaymentArgs {
  input: CreateManualPaymentInput;
}


export interface MutationCreateMemberArgs {
  data: CreateMemberInput;
}


export interface MutationCreateMembershipArgs {
  data: CreateMembershipInput;
}


export interface MutationCreateOrderIncidentArgs {
  input: CreateOrderIncidentInput;
}


export interface MutationCreateOrderIncidentCategoryArgs {
  input: CreateOrderIncidentCategoryInput;
}


export interface MutationCreateOrderNoteArgs {
  input: OrderNoteInput;
}


export interface MutationCreateOrderShipmentParcelArgs {
  input: CreateOrderShipmentParcelInput;
}


export interface MutationCreateOrderTagArgs {
  input: CreateOrderTagInput;
}


export interface MutationCreateOrderTemplateArgs {
  input: CreateOrderTemplateInput;
}


export interface MutationCreateParcelOptionArgs {
  input: CreateParcelOptionInput;
}


export interface MutationCreatePaymentMethodArgs {
  input: CreatePaymentMethodInput;
}


export interface MutationCreateProductArgs {
  data: CreateProductInput;
}


export interface MutationCreateProductAssociationArgs {
  input: CreateProductAssociationInput;
}


export interface MutationCreateProductAttributeArgs {
  data: CreateProductAttributeInput;
}


export interface MutationCreateProductOptionArgs {
  data: CreateProductOptionInput;
}


export interface MutationCreatePromotionArgs {
  data: CreatePromotionInput;
}


export interface MutationCreateShipmentParcelArgs {
  input: CreateShipmentParcelInput;
}


export interface MutationCreateShippingCategoryArgs {
  input: CreateShippingCategoryInput;
}


export interface MutationCreateShippingMethodArgs {
  input: CreateShippingMethodInput;
}


export interface MutationCreateShippingScheduleArgs {
  input: CreateShippingScheduleInput;
}


export interface MutationCreateShippingSlotArgs {
  input: CreateShippingSlotInput;
}


export interface MutationCreateStockCategoryArgs {
  input: CreateStockCategoryInput;
}


export interface MutationCreateStockItemArgs {
  input: CreateStockItemInput;
}


export interface MutationCreateStockItemComponentArgs {
  input: CreateStockItemComponentInput;
}


export interface MutationCreateStockLotArgs {
  input: CreateStockLotInput;
}


export interface MutationCreateStockScheduleArgs {
  input: StockScheduleCreateInput;
}


export interface MutationCreateSubscriberArgs {
  data: CreateSubscriberInput;
}


export interface MutationCreateSubscriberTagArgs {
  input: CreateSubscriberTagInput;
}


export interface MutationCreateSubscriptionArgs {
  data: CreateSubscriptionInput;
}


export interface MutationCreateSubscriptionMembershipBenefitArgs {
  data: SubscriptionMembershipBenefitInput;
}


export interface MutationCreateSubscriptionRunArgs {
  input: SubscriptionRunInput;
  variantId: Scalars['Int']['input'];
}


export interface MutationCreateSubscriptionVariantArgs {
  data: SubscriptionVariantInput;
}


export interface MutationCreateSubscriptionVariantProductQuantityArgs {
  data: SubscriptionVariantProductQuantityInput;
}


export interface MutationCreateTaxCategoryArgs {
  input: CreateTaxCategoryInput;
}


export interface MutationCreateTaxRateArgs {
  input: CreateTaxRateInput;
}


export interface MutationCreateTaxonArgs {
  data: CreateTaxonInput;
}


export interface MutationCreateUserArgs {
  input: CreateUserInput;
}


export interface MutationCreateUserAddressArgs {
  input: CreateUserAddressInput;
}


export interface MutationCreateVoucherArgs {
  input: CreateVoucherInput;
}


export interface MutationCreateVoucherProgramArgs {
  input: CreateVoucherProgramInput;
}


export interface MutationCreateWalletArgs {
  input: CreateWalletInput;
}


export interface MutationCreateWarehouseArgs {
  input: CreateWarehouseInput;
}


export interface MutationCreditWalletArgs {
  input: CreditWalletInput;
}


export interface MutationDeactivateMemberArgs {
  id: Scalars['Int']['input'];
}


export interface MutationDeactivateUserMembershipArgs {
  input: DeactivateUserMembershipInput;
}


export interface MutationDebitWalletArgs {
  input: DebitWalletInput;
}


export interface MutationDebitWalletPaymentArgs {
  input: DebitWalletPaymentInput;
}


export interface MutationDeleteGeoRegionDefinitionArgs {
  id: Scalars['Int']['input'];
}


export interface MutationDeleteGlobalVariableArgs {
  input: DeleteGlobalVariableInput;
}


export interface MutationDeleteStockItemComponentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationDeleteUserVariableArgs {
  input: DeleteUserVariableInput;
}


export interface MutationDeliverOrderShipmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationDenyOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationEnquireCartArgs {
  data: EnquireCartInput;
}


export interface MutationExpireOrderArgs {
  id: Scalars['Int']['input'];
  notifyUser?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface MutationExportMemberReportArgs {
  filter: MemberFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationExportStockItemQuantitiesArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  orderFilter: OrderFilterInput;
  stockItemFilter?: InputMaybe<StockItemFilterInput>;
}


export interface MutationFailOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationForgotPasswordArgs {
  credentials: ForgotPasswordInput;
}


export interface MutationGeneratePromotionBulkCouponCodesArgs {
  promotionId: Scalars['Int']['input'];
}


export interface MutationGenerateSubscriptionEntriesArgs {
  runId: Scalars['Int']['input'];
}


export interface MutationLoginUserArgs {
  credentials: CredentialsInput;
  options?: InputMaybe<LoginOptionsInput>;
}


export interface MutationManifestStockLotArgs {
  ref: Scalars['String']['input'];
}


export interface MutationMarkOrderShipmentLabelledArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationPackOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationPauseSubscriberArgs {
  id: Scalars['Int']['input'];
}


export interface MutationPositionOrderIncidentCategoryArgs {
  input: PositionOrderIncidentCategoryInput;
}


export interface MutationPositionProductAssociationArgs {
  input: PositionProductAssociationInput;
}


export interface MutationPrintDpdSpreadsheetArgs {
  filter: OrderFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintOrderGiftMessageArgs {
  filter: OrderFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintOrderManifestArgs {
  filter: OrderFilterInput;
}


export interface MutationPrintOrderManifestSpreadsheetArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  orderIds: Array<Scalars['Int']['input']>;
}


export interface MutationPrintOrderManifestV2Args {
  filter: OrderFilterInput;
}


export interface MutationPrintOrderReportArgs {
  filter: OrderFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintOrderShipmentLabelsArgs {
  input: PrintOrderShipmentLabelsInput;
}


export interface MutationPrintOrderTaxInvoiceArgs {
  filter: OrderFilterInput;
}


export interface MutationPrintPackFleetSpreadsheetArgs {
  filter: OrderFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintRouteManifestArgs {
  orderFilter: OrderFilterInput;
  stockItemFilter: StockItemFilterInput;
}


export interface MutationPrintRouteSummaryArgs {
  orderFilter: OrderFilterInput;
  stockItemFilter: StockItemFilterInput;
}


export interface MutationPrintRoyalMailReportArgs {
  filter: OrderFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintShipmentLabelsArgs {
  filter: ShipmentFilterInput;
}


export interface MutationPrintShipmentOrderGiftMessageArgs {
  filter: ShipmentFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintShipmentOrderManifestArgs {
  filter: ShipmentFilterInput;
}


export interface MutationPrintStockItemReportArgs {
  filter: StockItemFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintStockLotAdjustmentArgs {
  filter: StockItemFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintSubscriberReportArgs {
  filter: SubscriberFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationPrintVouchersManifestArgs {
  voucherIds: Array<Scalars['Int']['input']>;
}


export interface MutationProcessBulkOrderArgs {
  id: Scalars['Int']['input'];
  orderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}


export interface MutationProcessCartArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationProcessOrderArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationProcessOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationProcessSubscriptionEntriesArgs {
  entryIds: Array<Scalars['Int']['input']>;
}


export interface MutationProcessSubscriptionEntriesPaymentArgs {
  entryIds: Array<Scalars['Int']['input']>;
}


export interface MutationPublishOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationQueueSubscriptionRunProcessEntriesArgs {
  runId: Scalars['Int']['input'];
}


export interface MutationQueueSubscriptionRunProcessPaymentsArgs {
  runId: Scalars['Int']['input'];
}


export interface MutationReconOrderShipmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationReconShipmentArgs {
  input: ReconShipmentInput;
}


export interface MutationRecreateCartArgs {
  orderId: Scalars['Int']['input'];
  skipAgreements?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface MutationRedeemCartPaymentArgs {
  input: RedeemCartPaymentInput;
}


export interface MutationRefreshUserArgs {
  options?: InputMaybe<LoginOptionsInput>;
}


export interface MutationRefundCartPaymentArgs {
  input: RefundCartPaymentInput;
}


export interface MutationRefundOrderToWalletArgs {
  input: RefundOrderToWalletInput;
}


export interface MutationRegisterUserArgs {
  credentials: RegistrationInput;
  options?: InputMaybe<LoginOptionsInput>;
}


export interface MutationRemoveCartItemArgs {
  data: RemoveCartItemInput;
}


export interface MutationRemoveIncidentReplacementOrderArgs {
  id: Scalars['Int']['input'];
}


export interface MutationRemoveOrderItemArgs {
  data: RemoveOrderItemInput;
}


export interface MutationRemoveOrderTagsArgs {
  input: OrderTagInput;
}


export interface MutationRemoveOrderTemplateItemArgs {
  itemId: Scalars['Int']['input'];
  templateId: Scalars['Int']['input'];
}


export interface MutationRemoveProductRegionsArgs {
  data: UpdateProductRegionsInput;
}


export interface MutationRemoveProductTaxonArgs {
  data: RemoveProductTaxonInput;
}


export interface MutationRemoveProductWishlistItemArgs {
  input: ProductWishlistItemInput;
}


export interface MutationRemoveShipmentParcelArgs {
  id: Scalars['Int']['input'];
}


export interface MutationRemoveSubscriberTagsArgs {
  input: SubscriberTagInput;
}


export interface MutationReportProductAvailabilityArgs {
  input: ReportProductAvailabilityInput;
}


export interface MutationReportProductsArgs {
  input: ReportProductInput;
}


export interface MutationRequestCartPaymentArgs {
  input: RequestCartPaymentInput;
}


export interface MutationRequestNewCartPaymentArgs {
  input: RequestNewCartPaymentInput;
}


export interface MutationRequestOrderShipmentSlotArgs {
  input: RequestOrderShipmentSlotInput;
}


export interface MutationRescheduleOrderShipmentArgs {
  input: RescheduleOrderShipmentInput;
}


export interface MutationRescheduleShipmentArgs {
  input: RescheduleShipmentInput;
}


export interface MutationReserveOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationResetPasswordArgs {
  credentials: ResetPasswordInput;
}


export interface MutationResetUserPasswordArgs {
  input: ResetUserPasswordInput;
}


export interface MutationSendAbandonedCartEmailArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSendEntryNotificationArgs {
  entryId: Scalars['Int']['input'];
  type: SubscriptionEntryNotificationType;
}


export interface MutationSendMembershipSignUpEmailArgs {
  memberId: Scalars['Int']['input'];
}


export interface MutationSendOrderEnquiryEmailArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSendOrderRecievedEmailArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSendOrdersShippedEmailArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSendPasswordResetEmailArgs {
  id: Scalars['Int']['input'];
}


export interface MutationSendRequestPaymentEmailArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSendSubscriberEmailArgs {
  subscriberId: Scalars['Int']['input'];
  type: SubscriberNotificationType;
}


export interface MutationSendSubscriptionEntrySmsArgs {
  entryIds: Array<Scalars['Int']['input']>;
  smsType: SubscriptionVariantSmsType;
}


export interface MutationSendVoucherEmailArgs {
  voucherId: Scalars['Int']['input'];
}


export interface MutationSetGlobalVariableArgs {
  input: SetGlobalVariableInput;
}


export interface MutationSetOrderShipmentFixedCostArgs {
  input: SetOrderShipmentFixedCostInput;
}


export interface MutationSetOrderTemplateItemArgs {
  input: OrderTemplateItemInput;
}


export interface MutationSetProductPositionArgs {
  data: SetProductPositionInput;
}


export interface MutationSetUserVariableArgs {
  input: SetUserVariableInput;
}


export interface MutationShipOrderShipmentArgs {
  orderId: Scalars['Int']['input'];
  sendNotification?: Scalars['Boolean']['input'];
}


export interface MutationShipmentPrintOrderCourierSpreadsheetArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  orderIds: Array<Scalars['Int']['input']>;
}


export interface MutationShipmentPrintPackFleetSpreadsheetArgs {
  filter: ShipmentFilterInput;
  format?: InputMaybe<Scalars['String']['input']>;
}


export interface MutationShipmentPrintRoyalMailReportArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  shipmentFilter: ShipmentFilterInput;
}


export interface MutationShipmentsPrintRouteManifestArgs {
  shipmentFilter: ShipmentFilterInput;
  stockItemFilter: StockItemFilterInput;
}


export interface MutationShipmentsPrintRouteSummaryArgs {
  shipmentFilter: ShipmentFilterInput;
  stockItemFilter: StockItemFilterInput;
}


export interface MutationShopActivateMemberArgs {
  input: ShopActivateMemberInput;
}


export interface MutationShopActivateSubscriberArgs {
  input: ShopActivateSubscriberInput;
}


export interface MutationShopAddWishlistItemArgs {
  productId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}


export interface MutationShopArchiveUserPaymentMethodArgs {
  input: ArchiveUserPaymentMethodInput;
}


export interface MutationShopCancelCartPaymentArgs {
  input: ShopCancelCartPaymentInput;
}


export interface MutationShopCancelOrderArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationShopCheckoutOrderArgs {
  input: ShopCheckoutCartInput;
}


export interface MutationShopCreateCartArgs {
  input: ShopCreateCartInput;
}


export interface MutationShopCreateCartPaymentArgs {
  input: ShopCreateCartPaymentInput;
}


export interface MutationShopCreateCartTokenArgs {
  input: ShopCreateCartTokenInput;
}


export interface MutationShopCreateMemberArgs {
  input: ShopCreateMemberInput;
}


export interface MutationShopCreateNewCartPaymentArgs {
  input: ShopCreateNewCartPaymentInput;
}


export interface MutationShopCreateSubscriberArgs {
  input: ShopCreateSubscriberInput;
}


export interface MutationShopCreateUserAddressArgs {
  input: ShopCreateUserAddressInput;
}


export interface MutationShopCreateUserPaymentMethodArgs {
  input: ShopCreateUserPaymentMethodInput;
}


export interface MutationShopCreateWalletPaymentArgs {
  input: ShopCreateWalletPaymentInput;
}


export interface MutationShopDeactivateMemberArgs {
  input: ShopDeactivateMemberInput;
}


export interface MutationShopDeleteCartItemArgs {
  input: ShopDeleteCartItemInput;
}


export interface MutationShopDeleteUserAddressArgs {
  input: ShopDeleteUserAddressInput;
}


export interface MutationShopEnquireOrderArgs {
  input: ShopEnquireCartInput;
}


export interface MutationShopExportOrderInvoiceArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationShopPauseSubscriberArgs {
  input: ShopPauseSubscriberInput;
}


export interface MutationShopProcessCartArgs {
  input: ShopProcessCartInput;
}


export interface MutationShopRecreateCartArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationShopRedeemCartPaymentArgs {
  input: ShopRedeemCartPaymentInput;
}


export interface MutationShopRemoveWishlistItemArgs {
  productId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}


export interface MutationShopRequestOrderShipmentSlotArgs {
  input: ShopRequestOrderShipmentSlotInput;
}


export interface MutationShopSubscriberDeclarationArgs {
  input: ShopSubscriberDeclarationInput;
}


export interface MutationShopUpdateCartAddressArgs {
  input: ShopUpdateCartAddressInput;
}


export interface MutationShopUpdateCartCollectionPointArgs {
  input: ShopUpdateCartCollectionPointInput;
}


export interface MutationShopUpdateCartCouponArgs {
  input: ShopUpdateCartCouponInput;
}


export interface MutationShopUpdateCartCustomerArgs {
  input: ShopUpdateCartCustomerInput;
}


export interface MutationShopUpdateCartGiftOptionsArgs {
  input: ShopUpdateCartGiftOptionsInput;
}


export interface MutationShopUpdateCartInstructionsArgs {
  input: ShopUpdateCartInstructionsInput;
}


export interface MutationShopUpdateCartItemArgs {
  input: ShopUpdateCartItemInput;
}


export interface MutationShopUpdateCartItemsArgs {
  input: ShopUpdateCartItemsInput;
}


export interface MutationShopUpdateCartReferrerArgs {
  input: ShopUpdateCartReferrerInput;
}


export interface MutationShopUpdateSubscriberAddressArgs {
  input: ShopUpdateSubscriberAddressInput;
}


export interface MutationShopUpdateSubscriberPaymentMethodArgs {
  input: ShopUpdateSubscriberPaymentMethodInput;
}


export interface MutationShopUpdateUserArgs {
  input: ShopUpdateUserInput;
}


export interface MutationShopUpdateUserAddressArgs {
  input: ShopUpdateUserAddressInput;
}


export interface MutationShopUpdateUserPasswordArgs {
  input: ShopUpdateUserPasswordInput;
}


export interface MutationShopUserDeclarationArgs {
  input: ShopUserDeclarationInput;
}


export interface MutationSubmitOrderShipmentWaybillArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationSyncMediaToContentArgs {
  url: Scalars['String']['input'];
}


export interface MutationTransitionShipmentArgs {
  input: TransitionShipmentInput;
}


export interface MutationUnallocateOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationUnlockSubscriptionRunProcessingArgs {
  runId: Scalars['Int']['input'];
}


export interface MutationUnpackOrderFulfilmentArgs {
  orderId: Scalars['Int']['input'];
}


export interface MutationUnpublishOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface MutationUpdateAdArgs {
  input: UpdateAdInput;
}


export interface MutationUpdateAgreementArgs {
  input: UpdateAgreementInput;
}


export interface MutationUpdateAuthRoleArgs {
  input: UpdateAuthRoleInput;
}


export interface MutationUpdateBulkOrderArgs {
  input: UpdateBulkOrderInput;
}


export interface MutationUpdateCartCollectionPointArgs {
  input: UpdateCartCollectionPointInput;
}


export interface MutationUpdateCartGiftOptionsArgs {
  data: UpdateCartGiftOptionsInput;
}


export interface MutationUpdateCartInstructionsArgs {
  data: UpdateCartInstructionsInput;
}


export interface MutationUpdateCartItemArgs {
  data: UpdateCartItemInput;
}


export interface MutationUpdateChannelArgs {
  data: UpdateChannelInput;
}


export interface MutationUpdateCollectionPointArgs {
  data: UpdateCollectionPointInput;
}


export interface MutationUpdateGeoRegionArgs {
  data: UpdateGeoRegionInput;
}


export interface MutationUpdateGeoRegionDefinitionArgs {
  data: UpdateGeoRegionDefinitionInput;
}


export interface MutationUpdateManyProductsArgs {
  data: Array<UpdateProductInput>;
}


export interface MutationUpdateMemberArgs {
  data: UpdateMemberInput;
}


export interface MutationUpdateMembershipArgs {
  data: UpdateMembershipInput;
}


export interface MutationUpdateOrderGiftOptionsArgs {
  data: UpdateOrderGiftOptionsInput;
}


export interface MutationUpdateOrderIncidentArgs {
  input: UpdateOrderIncidentInput;
}


export interface MutationUpdateOrderIncidentCategoryArgs {
  input: UpdateOrderIncidentCategoryInput;
}


export interface MutationUpdateOrderInstructionsArgs {
  data: UpdateOrderInstructionsInput;
}


export interface MutationUpdateOrderItemArgs {
  data: UpdateOrderItemInput;
}


export interface MutationUpdateOrderShipmentArgs {
  input: UpdateOrderShipmentInput;
}


export interface MutationUpdateOrderTagArgs {
  input: UpdateOrderTagInput;
}


export interface MutationUpdateParcelOptionArgs {
  input: UpdateParcelOptionInput;
}


export interface MutationUpdatePaymentMethodArgs {
  input: UpdatePaymentMethodInput;
}


export interface MutationUpdatePaymentMethodChannelEnabledArgs {
  input: PaymentMethodChannelEnabledInput;
}


export interface MutationUpdateProductArgs {
  data: UpdateProductInput;
}


export interface MutationUpdateProductAssociationArgs {
  input: UpdateProductAssociationInput;
}


export interface MutationUpdateProductAttributeArgs {
  data: UpdateProductAttributeInput;
  id: Scalars['Int']['input'];
}


export interface MutationUpdateProductKeywordsArgs {
  data: UpdateProductKeywordInput;
}


export interface MutationUpdateProductOptionArgs {
  data: UpdateProductOptionInput;
}


export interface MutationUpdatePromotionArgs {
  data: UpdatePromotionInput;
}


export interface MutationUpdatePromotionEnabledArgs {
  enabled: Scalars['Boolean']['input'];
  promotionId: Scalars['Int']['input'];
}


export interface MutationUpdateShipmentParcelArgs {
  input: UpdateShipmentParcelInput;
}


export interface MutationUpdateShippingCategoryArgs {
  input: UpdateShippingCategoryInput;
}


export interface MutationUpdateShippingMethodArgs {
  input: UpdateShippingMethodInput;
}


export interface MutationUpdateShippingMethodChannelEnabledArgs {
  input: ShippingMethodChannelEnabledInput;
}


export interface MutationUpdateShippingScheduleArgs {
  input: UpdateShippingScheduleInput;
}


export interface MutationUpdateShippingScheduleSlotsArgs {
  input: UpdateShippingScheduleSlotsInput;
}


export interface MutationUpdateShippingSlotArgs {
  input: UpdateShippingSlotInput;
}


export interface MutationUpdateStockCategoryArgs {
  input: UpdateStockCategoryInput;
}


export interface MutationUpdateStockItemArgs {
  input: UpdateStockItemInput;
}


export interface MutationUpdateStockItemCategoryArgs {
  input: UpdateStockItemCategoryInput;
}


export interface MutationUpdateStockItemComponentArgs {
  input: UpdateStockItemComponentInput;
}


export interface MutationUpdateStockItemTagsArgs {
  input: UpdateStockItemTagsInput;
}


export interface MutationUpdateStockLotArgs {
  input: UpdateStockLotInput;
}


export interface MutationUpdateStockScheduleArgs {
  input: StockScheduleUpdateInput;
}


export interface MutationUpdateStockScheduleEnabledArgs {
  input: StockScheduleUpdateEnabledInput;
}


export interface MutationUpdateSubscriberArgs {
  data: UpdateSubscriberInput;
}


export interface MutationUpdateSubscriberAddressArgs {
  data: UpdateSubscriberAddressInput;
}


export interface MutationUpdateSubscriberTagArgs {
  input: UpdateSubscriberTagInput;
}


export interface MutationUpdateSubscriptionArgs {
  data: UpdateSubscriptionInput;
}


export interface MutationUpdateSubscriptionRunArgs {
  input: SubscriptionRunInput;
  runId: Scalars['Int']['input'];
}


export interface MutationUpdateSubscriptionVariantArgs {
  data: SubscriptionVariantInput;
}


export interface MutationUpdateSubscriptionVariantProductQuantityArgs {
  data: SubscriptionVariantProductQuantityInput;
}


export interface MutationUpdateTaxCategoryArgs {
  input: UpdateTaxCategoryInput;
}


export interface MutationUpdateTaxRateArgs {
  input: UpdateTaxRateInput;
}


export interface MutationUpdateTaxonArgs {
  data: UpdateTaxonInput;
  id: Scalars['Int']['input'];
}


export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
}


export interface MutationUpdateUserAddressArgs {
  input: UpdateUserAddressInput;
}


export interface MutationUpdateUserRolesArgs {
  input: UpdateUserRolesInput;
}


export interface MutationUpdateVoucherProgramArgs {
  input: UpdateVoucherProgramInput;
}


export interface MutationUpdateWarehouseArgs {
  input: UpdateWarehouseInput;
}


export interface MutationUserTokenArgs {
  id: Scalars['Int']['input'];
}

export interface NotificationChannelObject {
  __typename?: 'NotificationChannelObject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface NotificationLogFilterInput {
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  entityId?: InputMaybe<Array<Scalars['String']['input']>>;
  entityType?: InputMaybe<Array<Scalars['String']['input']>>;
  recipient?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sentAt?: InputMaybe<DateRangeFilterInput>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface NotificationLogObject {
  __typename?: 'NotificationLogObject';
  channelName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notificationName: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  recipientName: Scalars['String']['output'];
  references?: Maybe<Array<NotificationRefObject>>;
  sentAt: Scalars['DateTimeScalar']['output'];
  status: NotificationSendStatus;
}

export interface NotificationRefObject {
  __typename?: 'NotificationRefObject';
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
}

export enum NotificationSendStatus {
  Failed = 'FAILED',
  Sent = 'SENT',
  Unsupported = 'UNSUPPORTED'
}

export interface NotificationTypeObject {
  __typename?: 'NotificationTypeObject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface OrderAdjustmentObject {
  __typename?: 'OrderAdjustmentObject';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  type: OrderAdjustmentType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum OrderAdjustmentType {
  Custom = 'CUSTOM',
  PromotionDiscount = 'PROMOTION_DISCOUNT',
  ShippingDiscount = 'SHIPPING_DISCOUNT',
  ShippingFee = 'SHIPPING_FEE',
  ShippingTax = 'SHIPPING_TAX'
}

export interface OrderAgreementObject {
  __typename?: 'OrderAgreementObject';
  agreed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface OrderCheckoutRequirementsObject {
  __typename?: 'OrderCheckoutRequirementsObject';
  canCheckout: Scalars['Boolean']['output'];
  requirements: Array<CheckoutRequirementObject>;
}

export interface OrderCouponEligibilityObject {
  __typename?: 'OrderCouponEligibilityObject';
  exists: Scalars['Boolean']['output'];
  promotionEligibilities?: Maybe<Array<CouponPromotionEligibilityObject>>;
}

export interface OrderFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  authorId?: InputMaybe<Scalars['Int']['input']>;
  bulkOrderId?: InputMaybe<Scalars['Int']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  checkoutAt?: InputMaybe<DateRangeFilterInput>;
  collectionPointIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  fulfilmentStates?: InputMaybe<Array<FulfilmentState>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isGift?: InputMaybe<Scalars['Boolean']['input']>;
  orderSources?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentMethodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  paymentStates?: InputMaybe<Array<OrderPaymentState>>;
  productVariantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  promotionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  relativeCheckoutAt?: InputMaybe<RelativeDateType>;
  relativeShippingSlotAt?: InputMaybe<RelativeDateType>;
  search?: InputMaybe<Scalars['String']['input']>;
  shipmentSlotIds?: InputMaybe<Array<Scalars['String']['input']>>;
  shipmentStates?: InputMaybe<Array<ShipmentState>>;
  shippingAddressCoordinateFilter?: InputMaybe<CoordinatesFilterInput>;
  shippingAddressGeoRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategories?: InputMaybe<OrderShippingCategoryFilterInput>;
  shippingMethodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingSlotAt?: InputMaybe<DateRangeFilterInput>;
  states?: InputMaybe<Array<OrderState>>;
  stockItemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  subscriptionRunIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  taxon?: InputMaybe<OrderTaxonFilterInput>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export enum OrderIncidentApprovalState {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  Pending = 'PENDING'
}

export interface OrderIncidentCategoryFilterInput {
  allowedOrderStates?: InputMaybe<Array<OrderState>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  itemActionAllowed?: InputMaybe<Array<OrderIncidentItemAction>>;
  itemActionRequired?: InputMaybe<Array<OrderIncidentItemAction>>;
  otherItemActionDefault?: InputMaybe<Array<OrderIncidentItemAction>>;
  requireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  steps?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface OrderIncidentCategoryObject {
  __typename?: 'OrderIncidentCategoryObject';
  allowedOrderStates: Array<OrderState>;
  config?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  itemActionAllowed: Array<OrderIncidentItemAction>;
  itemActionRequired: Array<OrderIncidentItemAction>;
  name: Scalars['String']['output'];
  otherItemActionDefault: Array<OrderIncidentItemAction>;
  position: Scalars['Int']['output'];
  requireApproval: Scalars['Boolean']['output'];
  steps: Array<Scalars['String']['output']>;
  subcategories: Array<OrderIncidentSubcategoryObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderIncidentFilterInput {
  approvalStates?: InputMaybe<Array<OrderIncidentApprovalState>>;
  approvedAt?: InputMaybe<DateRangeFilterInput>;
  approverUserId?: InputMaybe<Scalars['Int']['input']>;
  authorUserId?: InputMaybe<Scalars['Int']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  deniedAt?: InputMaybe<DateRangeFilterInput>;
  failedAt?: InputMaybe<DateRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderUserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  publishedAt?: InputMaybe<DateRangeFilterInput>;
  replacementOrderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  resolutionStates?: InputMaybe<Array<OrderIncidentResolutionState>>;
  resolvedAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  submittedAt?: InputMaybe<DateRangeFilterInput>;
}

export enum OrderIncidentItemAction {
  Refund = 'REFUND',
  Replace = 'REPLACE',
  Return = 'RETURN'
}

export interface OrderIncidentItemInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  itemId: Scalars['Int']['input'];
  refund: Scalars['Int']['input'];
  replace: Scalars['Int']['input'];
  return: Scalars['Int']['input'];
}

export interface OrderIncidentItemObject {
  __typename?: 'OrderIncidentItemObject';
  id: Scalars['Int']['output'];
  incident: OrderIncidentObject;
  orderItem: OrderItemObject;
  refund: Scalars['Int']['output'];
  replace: Scalars['Int']['output'];
  return: Scalars['Int']['output'];
}

export interface OrderIncidentObject {
  __typename?: 'OrderIncidentObject';
  approvalState: OrderIncidentApprovalState;
  approvedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  approverUser?: Maybe<UserObject>;
  authorUser: UserObject;
  category?: Maybe<OrderIncidentCategoryObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  deniedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  errorDescription?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  items: Array<OrderIncidentItemObject>;
  order: OrderObject;
  publishedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  refundTotal: Scalars['Int']['output'];
  replaceTotal: Scalars['Int']['output'];
  replacementOrder?: Maybe<OrderObject>;
  replacementWallet?: Maybe<WalletObject>;
  resolutionState: OrderIncidentResolutionState;
  resolvedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  returnTotal: Scalars['Int']['output'];
  subcategory?: Maybe<OrderIncidentSubcategoryObject>;
  submittedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum OrderIncidentResolutionState {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Resolved = 'RESOLVED',
  Skipped = 'SKIPPED',
  Unresolved = 'UNRESOLVED'
}

export interface OrderIncidentSubcategoryInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface OrderIncidentSubcategoryObject {
  __typename?: 'OrderIncidentSubcategoryObject';
  category: OrderIncidentCategoryObject;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
}

export interface OrderItemContextObject {
  __typename?: 'OrderItemContextObject';
  associationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  item: OrderItemObject;
  objectId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  queryId?: Maybe<Scalars['String']['output']>;
  queryIndex?: Maybe<Scalars['String']['output']>;
  shopAdId?: Maybe<Scalars['Int']['output']>;
  shopAdPlacement?: Maybe<Scalars['String']['output']>;
  taxonId?: Maybe<Scalars['Int']['output']>;
}

export interface OrderItemFilterInput {
  stockLotIds?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface OrderItemObject {
  __typename?: 'OrderItemObject';
  accountType: AccountType;
  adjustments: Array<OrderAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  context?: Maybe<OrderItemContextObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  creditTotal: Scalars['Int']['output'];
  fixedUnitPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  order: OrderObject;
  productName: Scalars['String']['output'];
  qtyRefundable: Scalars['Int']['output'];
  qtyReplaceable: Scalars['Int']['output'];
  qtyReturnable: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  salesTotal: Scalars['Int']['output'];
  source: OrderItemSource;
  taxAmount: Scalars['Int']['output'];
  taxRate: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
  units: Array<OrderItemUnitObject>;
  unitsTotal: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
  variantId: Scalars['Int']['output'];
  variantName?: Maybe<Scalars['String']['output']>;
}


export interface OrderItemObjectQtyRefundableArgs {
  incidentId?: InputMaybe<Scalars['Int']['input']>;
}


export interface OrderItemObjectQtyReplaceableArgs {
  incidentId?: InputMaybe<Scalars['Int']['input']>;
}


export interface OrderItemObjectQtyReturnableArgs {
  incidentId?: InputMaybe<Scalars['Int']['input']>;
}

export enum OrderItemSource {
  Order = 'ORDER',
  Promotional = 'PROMOTIONAL',
  Replacement = 'REPLACEMENT',
  Shipping = 'SHIPPING',
  Subscription = 'SUBSCRIPTION'
}

export interface OrderItemUnitObject {
  __typename?: 'OrderItemUnitObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  stockItem?: Maybe<StockItemObject>;
  stockLot?: Maybe<StockLotObject>;
  stockLotRef?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderNoteFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface OrderNoteInput {
  message: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface OrderNoteObject {
  __typename?: 'OrderNoteObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  authorUser?: Maybe<UserObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderObject {
  __typename?: 'OrderObject';
  adjustments: Array<OrderAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  authorUser?: Maybe<UserObject>;
  balance: Scalars['Int']['output'];
  billingAddress?: Maybe<AddressObject>;
  bulkOrderEntry?: Maybe<BulkOrderEntryObject>;
  canceledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channel: ChannelObject;
  checkoutAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  checkoutRequirements: OrderCheckoutRequirementsObject;
  collectionPoint?: Maybe<CollectionPointObject>;
  completedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  creditItemsTotal: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  deferAllocation: Scalars['Boolean']['output'];
  enquiredAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  fulfilment?: Maybe<FulfilmentObject>;
  giftMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  incidents: Array<OrderIncidentObject>;
  instructions?: Maybe<Scalars['String']['output']>;
  isGift: Scalars['Boolean']['output'];
  items: Array<OrderItemObject>;
  itemsTotal: Scalars['Int']['output'];
  lastProcessedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  notes: Array<OrderNoteObject>;
  number?: Maybe<Scalars['String']['output']>;
  originalOrder?: Maybe<OrderObject>;
  outstandingTotal: Scalars['Int']['output'];
  paidTotal: Scalars['Int']['output'];
  paymentState: OrderPaymentState;
  payments: Array<PaymentObject>;
  printedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  processingAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  promotions: Array<PromotionObject>;
  quotedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  refundedTotal: Scalars['Int']['output'];
  requiredAgreements?: Maybe<Array<OrderAgreementObject>>;
  salesItemsTotal: Scalars['Int']['output'];
  shipment?: Maybe<ShipmentObject>;
  shippingAddress?: Maybe<AddressObject>;
  shippingAddressGeoRegion?: Maybe<GeoRegionObject>;
  skipAgreements: Scalars['Boolean']['output'];
  source?: Maybe<Scalars['String']['output']>;
  state: OrderState;
  subscriptionEntry?: Maybe<SubscriptionEntryObject>;
  subtotal: Scalars['Float']['output'];
  tags: Array<OrderTagObject>;
  taxTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  user?: Maybe<UserObject>;
  vouchers: Array<VoucherObject>;
  walletTransactions: Array<WalletTransactionObject>;
}

export enum OrderPaymentState {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Cancelled = 'CANCELLED',
  Cart = 'CART',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Refunded = 'REFUNDED',
  Skipped = 'SKIPPED'
}

export interface OrderProcessorObject {
  __typename?: 'OrderProcessorObject';
  priority: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
}

export interface OrderSalesStatsObject {
  __typename?: 'OrderSalesStatsObject';
  count: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  sumAdjustmentsTotal: Scalars['Int']['output'];
  sumPaidTotal: Scalars['Int']['output'];
  sumRefundedTotal: Scalars['Int']['output'];
  sumTotal: Scalars['Int']['output'];
}

export interface OrderShippingCategoryFilterInput {
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface OrderSourceObject {
  __typename?: 'OrderSourceObject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export enum OrderState {
  Attention = 'ATTENTION',
  Cancelled = 'CANCELLED',
  Cart = 'CART',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Enquiry = 'ENQUIRY',
  Expired = 'EXPIRED',
  Processing = 'PROCESSING',
  Quoted = 'QUOTED'
}

export interface OrderStateCountsObject {
  __typename?: 'OrderStateCountsObject';
  count: Scalars['Int']['output'];
  state: OrderState;
}

export interface OrderTagFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface OrderTagInput {
  orderId: Scalars['Int']['input'];
  tagIds: Array<Scalars['Int']['input']>;
}

export interface OrderTagObject {
  __typename?: 'OrderTagObject';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface OrderTaxonFilterInput {
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface OrderTemplateFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface OrderTemplateItemInput {
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  templateId: Scalars['Int']['input'];
  unitPrice?: InputMaybe<Scalars['Int']['input']>;
}

export interface OrderTemplateItemObject {
  __typename?: 'OrderTemplateItemObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  resolvedUnitPrice: Scalars['Int']['output'];
  template: OrderTemplateObject;
  unitPrice?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface OrderTemplateObject {
  __typename?: 'OrderTemplateObject';
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  items: Array<OrderTemplateItemObject>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  version: Scalars['Int']['output'];
}

export interface PageInput {
  index?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
}

export interface ParcelOptionFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ParcelOptionObject {
  __typename?: 'ParcelOptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  dimensions?: Maybe<DimensionsObject>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
}

export interface PaymentErrorFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface PaymentMethodChannelEnabledInput {
  channelId: Scalars['Int']['input'];
  enable?: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
}

export interface PaymentMethodFilterInput {
  enabledChannelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface PaymentMethodObject {
  __typename?: 'PaymentMethodObject';
  canReuse: Scalars['Boolean']['output'];
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface PaymentObject {
  __typename?: 'PaymentObject';
  amount: Scalars['Int']['output'];
  canCancel: Scalars['Boolean']['output'];
  canRefund: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  method: PaymentMethodObject;
  order: OrderObject;
  providerDescription?: Maybe<Scalars['String']['output']>;
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  reference: Scalars['String']['output'];
  refundedAmount: Scalars['Int']['output'];
  refundedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  save: Scalars['Boolean']['output'];
  state: PaymentState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  userMethod?: Maybe<UserPaymentMethodObject>;
}

export interface PaymentProviderObject {
  __typename?: 'PaymentProviderObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export enum PaymentState {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  New = 'NEW',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Processing = 'PROCESSING',
  Redemption = 'REDEMPTION',
  Refunded = 'REFUNDED'
}

export interface PermissionObject {
  __typename?: 'PermissionObject';
  description?: Maybe<Scalars['String']['output']>;
  permission: Scalars['String']['output'];
}

export interface PointInput {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
}

export interface PolygonInput {
  exterior: Array<PointInput>;
  interior: Array<Array<PointInput>>;
}

export interface PositionOrderIncidentCategoryInput {
  id: Scalars['Int']['input'];
  position: Scalars['Int']['input'];
}

export interface PositionProductAssociationInput {
  associationId: Scalars['Int']['input'];
  position: Scalars['Int']['input'];
}

export interface PrintOrderShipmentLabelsInput {
  orderIds: Array<Scalars['Int']['input']>;
}

export interface PrintResultObject {
  __typename?: 'PrintResultObject';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ProductAssocationFilterInput {
  associationId: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductAssociatedProductInput {
  enabled: Scalars['Boolean']['input'];
  position: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
  recommendation: Scalars['Boolean']['input'];
}

export interface ProductAssociatedProductObject {
  __typename?: 'ProductAssociatedProductObject';
  associatedProduct: ProductObject;
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  product: ProductObject;
  recommendation: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAssociationConfigObject {
  __typename?: 'ProductAssociationConfigObject';
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  product: ProductObject;
  sourceMainTaxonEnable?: Maybe<Scalars['Boolean']['output']>;
  sourceTaxon?: Maybe<TaxonObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAssociationFilterInput {
  enabledChannelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductAssociationObject {
  __typename?: 'ProductAssociationObject';
  channels: Array<ChannelObject>;
  configs: Array<ProductAssociationConfigObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  productAssociations: Array<ProductAssociatedProductObject>;
  recommendationModel?: Maybe<Scalars['String']['output']>;
  sourceMainTaxonEnable: Scalars['Boolean']['output'];
  sourceTaxon?: Maybe<TaxonObject>;
  targetMainTaxonEnable: Scalars['Boolean']['output'];
  targetTaxons: Array<TaxonObject>;
  targets?: Maybe<Array<ProductAssociationTarget>>;
  testCase?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  translations: Array<ProductAssociationTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum ProductAssociationTarget {
  Cart = 'CART',
  Checkout = 'CHECKOUT',
  ProductDetail = 'PRODUCT_DETAIL'
}

export interface ProductAssociationTranslationInput {
  locale: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface ProductAssociationTranslationObject {
  __typename?: 'ProductAssociationTranslationObject';
  association: ProductAssociationObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductAttributeFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductAttributeObject {
  __typename?: 'ProductAttributeObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  translations: Array<ProductAttributeTranslationObject>;
  type: ProductAttributeType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface ProductAttributeObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductAttributeTranslationInput {
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface ProductAttributeTranslationObject {
  __typename?: 'ProductAttributeTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum ProductAttributeType {
  Boolean = 'BOOLEAN',
  Markdown = 'MARKDOWN',
  Number = 'NUMBER',
  Text = 'TEXT'
}

export interface ProductAttributeValueInput {
  attributeId: Scalars['Float']['input'];
  locale: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
}

export interface ProductAttributeValueObject {
  __typename?: 'ProductAttributeValueObject';
  attribute: ProductAttributeObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['JSON']['output'];
}

export interface ProductChannelFilterInput {
  channelIds: Array<Scalars['Int']['input']>;
}

export interface ProductChannelObject {
  __typename?: 'ProductChannelObject';
  channel: ChannelObject;
  disabledVariantCount: Scalars['Int']['output'];
  enabledVariantCount: Scalars['Int']['output'];
}

export interface ProductFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  association?: InputMaybe<ProductAssocationFilterInput>;
  attributeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  channel?: InputMaybe<ProductChannelFilterInput>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Array<Scalars['Int']['input']>>;
  publishStates?: InputMaybe<Array<ProductPublishState>>;
  publishedAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  stockItemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  taxon?: InputMaybe<ProductTaxonFilterInput>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ProductMediaInput {
  alt?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  placement?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  quality?: InputMaybe<Scalars['Float']['input']>;
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductMediaObject {
  __typename?: 'ProductMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  variant?: Maybe<ProductVariantObject>;
  width?: Maybe<Scalars['Int']['output']>;
}

export interface ProductObject {
  __typename?: 'ProductObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  associatedTaxons: Array<TaxonObject>;
  attributeValues: Array<ProductAttributeValueObject>;
  attributes: Array<ProductAttributeObject>;
  channels: Array<ProductChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mainMedia?: Maybe<ProductMediaObject>;
  mainTaxon: TaxonObject;
  media: Array<ProductMediaObject>;
  name: Scalars['String']['output'];
  options: Array<ProductOptionObject>;
  position?: Maybe<Scalars['Int']['output']>;
  priority: Scalars['Int']['output'];
  publishRequirements: ProductPublishRequirementsObject;
  publishedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  secondaryMedia?: Maybe<ProductMediaObject>;
  taxonPositions: Array<ProductPositionObject>;
  taxons: Array<TaxonObject>;
  translations: Array<ProductTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<ProductVariantObject>;
  variation: ProductVariation;
}


export interface ProductObjectAttributeValuesArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}


export interface ProductObjectAttributesArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}


export interface ProductObjectPositionArgs {
  taxonId: Scalars['Int']['input'];
}


export interface ProductObjectTaxonPositionsArgs {
  taxonIds: Array<Scalars['Int']['input']>;
}


export interface ProductObjectTaxonsArgs {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface ProductObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductOptionFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductOptionInput {
  name?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<ProductOptionTranslationInput>>;
  values?: InputMaybe<Array<ProductOptionValueInput>>;
}

export interface ProductOptionObject {
  __typename?: 'ProductOptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  products: Array<ProductObject>;
  translations: Array<ProductOptionTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  values: Array<ProductOptionValueObject>;
}


export interface ProductOptionObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductOptionTranslationInput {
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  option?: InputMaybe<ProductOptionInput>;
}

export interface ProductOptionTranslationObject {
  __typename?: 'ProductOptionTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductOptionValueInput {
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<ProductOptionValueTranslationInput>>;
}

export interface ProductOptionValueObject {
  __typename?: 'ProductOptionValueObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  option: ProductOptionObject;
  position: Scalars['Int']['output'];
  translations: Array<ProductOptionValueTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductOptionValueTranslationInput {
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface ProductOptionValueTranslationObject {
  __typename?: 'ProductOptionValueTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductPositionObject {
  __typename?: 'ProductPositionObject';
  position: Scalars['Int']['output'];
  product: ProductObject;
  taxon: TaxonObject;
}

export interface ProductPublishRequirementObject {
  __typename?: 'ProductPublishRequirementObject';
  code: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export interface ProductPublishRequirementsObject {
  __typename?: 'ProductPublishRequirementsObject';
  canPublish: Scalars['Boolean']['output'];
  requirements: Array<ProductPublishRequirementObject>;
}

export enum ProductPublishState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

export interface ProductRecommendationModelObject {
  __typename?: 'ProductRecommendationModelObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ProductReportOptionsInput {
  includeAttributes?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProductTaxonFilterInput {
  includeDescendents?: Scalars['Boolean']['input'];
  mainTaxon?: Scalars['Boolean']['input'];
  taxonIds?: Array<Scalars['Int']['input']>;
}

export interface ProductTranslationInput {
  contentRef?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name: Scalars['String']['input'];
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductTranslationObject {
  __typename?: 'ProductTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  product: ProductObject;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductVariantAdmissionProductInput {
  productId: Scalars['String']['input'];
  productName: Scalars['String']['input'];
}

export interface ProductVariantAdmissionProductObject {
  __typename?: 'ProductVariantAdmissionProductObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface ProductVariantChannelInput {
  agreementIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  available: Scalars['Boolean']['input'];
  availableRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  capAmount?: InputMaybe<Scalars['Int']['input']>;
  channelId: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  taxCategoryId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductVariantChannelObject {
  __typename?: 'ProductVariantChannelObject';
  agreements?: Maybe<Array<AgreementObject>>;
  available: Scalars['Boolean']['output'];
  availableRegions: Array<GeoRegionObject>;
  capAmount?: Maybe<Scalars['Int']['output']>;
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  taxCategory?: Maybe<TaxCategoryObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ProductVariantFeedOptionsInput {
  customLabel0?: InputMaybe<Scalars['String']['input']>;
  customLabel1?: InputMaybe<Scalars['String']['input']>;
  customLabel2?: InputMaybe<Scalars['String']['input']>;
  googleProductCategory?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductVariantFeedOptionsObject {
  __typename?: 'ProductVariantFeedOptionsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  customLabel0?: Maybe<Scalars['String']['output']>;
  customLabel1?: Maybe<Scalars['String']['output']>;
  customLabel2?: Maybe<Scalars['String']['output']>;
  googleProductCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export interface ProductVariantFilterInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  publishStates?: InputMaybe<Array<ProductPublishState>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductVariantInput {
  accountType?: InputMaybe<AccountType>;
  admissionProduct?: InputMaybe<ProductVariantAdmissionProductInput>;
  channels: Array<ProductVariantChannelInput>;
  feedOptions?: InputMaybe<ProductVariantFeedOptionsInput>;
  media: Array<ProductMediaInput>;
  name: Scalars['String']['input'];
  optionValues: Array<ProductVariantOptionValueInput>;
  stockItemId?: InputMaybe<Scalars['Int']['input']>;
  translations: Array<ProductVariantTranslationInput>;
}

export interface ProductVariantObject {
  __typename?: 'ProductVariantObject';
  accountType: AccountType;
  admissionProduct?: Maybe<ProductVariantAdmissionProductObject>;
  channel?: Maybe<ProductVariantChannelObject>;
  channels: Array<ProductVariantChannelObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  feedOptions?: Maybe<ProductVariantFeedOptionsObject>;
  id: Scalars['Int']['output'];
  media: Array<ProductMediaObject>;
  name: Scalars['String']['output'];
  optionValues: Array<ProductOptionValueObject>;
  position: Scalars['Int']['output'];
  product: ProductObject;
  stockItem?: Maybe<StockItemObject>;
  translations: Array<ProductVariantTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  voucherPrograms: Array<VoucherProgramObject>;
}


export interface ProductVariantObjectChannelArgs {
  channelCode?: InputMaybe<Scalars['String']['input']>;
}


export interface ProductVariantObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductVariantOptionValueInput {
  optionId: Scalars['Float']['input'];
  optionValueId: Scalars['Float']['input'];
}

export interface ProductVariantTranslationInput {
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductVariantTranslationObject {
  __typename?: 'ProductVariantTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: ProductVariantObject;
}

export enum ProductVariation {
  Option = 'OPTION',
  Single = 'SINGLE'
}

export interface ProductWishlistItemFilterInput {
  productId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductWishlistItemInput {
  productId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ProductWishlistItemObject {
  __typename?: 'ProductWishlistItemObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  product: ProductObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export interface PromotionActionInput {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
}

export interface PromotionActionObject {
  __typename?: 'PromotionActionObject';
  configuration: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  promotion: PromotionObject;
  type: Scalars['String']['output'];
  typeLabel?: Maybe<Scalars['String']['output']>;
}

export interface PromotionActionTypeObject {
  __typename?: 'PromotionActionTypeObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface PromotionFilterInput {
  active?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabledChannelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  isCoupon?: InputMaybe<Scalars['Boolean']['input']>;
  isReferrer?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface PromotionObject {
  __typename?: 'PromotionObject';
  actions: Array<PromotionActionObject>;
  adjustmentLabel?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  couponBulkQty?: Maybe<Scalars['Int']['output']>;
  couponBulkSecret?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  couponType: CouponType;
  couponUsageLimit?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  customerUsageLimit?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  exclusive: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orders: Array<OrderObject>;
  priority: Scalars['Int']['output'];
  referrerCode?: Maybe<Scalars['String']['output']>;
  ruleExplanation?: Maybe<Scalars['String']['output']>;
  rules: Array<PromotionRuleObject>;
  startsAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  status: PromotionStatus;
  updatedAt: Scalars['DateTimeScalar']['output'];
  usage: Scalars['Int']['output'];
  usageLimit?: Maybe<Scalars['Int']['output']>;
}

export interface PromotionRuleInput {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
}

export interface PromotionRuleObject {
  __typename?: 'PromotionRuleObject';
  configuration: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  promotion: PromotionObject;
  type: Scalars['String']['output'];
  typeLabel?: Maybe<Scalars['String']['output']>;
}

export interface PromotionRuleTypeObject {
  __typename?: 'PromotionRuleTypeObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export enum PromotionStatus {
  Expired = 'EXPIRED',
  Scheduled = 'SCHEDULED',
  Valid = 'VALID'
}

export interface Query {
  __typename?: 'Query';
  SubscriptionEntriesCount: Scalars['Int']['output'];
  ad: AdObject;
  address: AddressObject;
  addresses: Array<AddressObject>;
  addressesCount: Scalars['Int']['output'];
  ads: Array<AdObject>;
  adsCount: Scalars['Int']['output'];
  agreement: AgreementObject;
  agreementDeclarations: Array<AgreementDeclarationObject>;
  agreementDeclarationsCount: Scalars['Int']['output'];
  agreements: Array<AgreementObject>;
  agreementsCount: Scalars['Int']['output'];
  associatedProduct: ProductAssociatedProductObject;
  associatedProducts: Array<ProductAssociatedProductObject>;
  auth: AuthenticationObject;
  authPermissions: Array<PermissionObject>;
  authRole: AuthRoleObject;
  authRoles: Array<AuthRoleObject>;
  authRolesCount: Scalars['Float']['output'];
  authUser: UserObject;
  browseMedia: BrowseMediaObject;
  browseMediaFolder: Array<MediaFolderObject>;
  bulkOrder: BulkOrderObject;
  bulkOrders: Array<BulkOrderObject>;
  bulkOrdersCount: Scalars['Float']['output'];
  channel: ChannelObject;
  channelByCode: ChannelObject;
  channels: Array<ChannelObject>;
  channelsCount: Scalars['Int']['output'];
  checkCartPaymentBalance: VoucherPaymentBalanceObject;
  collectionPoint: CollectionPointObject;
  collectionPoints: Array<CollectionPointObject>;
  collectionPointsCount: Scalars['Int']['output'];
  content: ContentRefObject;
  contentProviders: Array<ContentProviderObject>;
  contents: Array<ContentRefObject>;
  couponEligibility: OrderCouponEligibilityObject;
  defaultChannel: ChannelObject;
  distinctChannelLocales: Array<ChannelLocaleObject>;
  fulfilment: FulfilmentObject;
  fulfilments: Array<FulfilmentObject>;
  fulfilmentsCount: Scalars['Float']['output'];
  geoRegion: GeoRegionObject;
  geoRegionAddressMatch?: Maybe<GeoRegionMatchResultObject>;
  geoRegionDefinitions: Array<GeoRegionDefinitionObject>;
  geoRegions: Array<GeoRegionObject>;
  geoRegionsCount: Scalars['Int']['output'];
  getMedia?: Maybe<MediaObject>;
  getMediaUploadUrl: MediaUploadUrlObject;
  globalVariable: VariableObject;
  globalVariables: Array<VariableObject>;
  member: MemberObject;
  members: Array<MemberObject>;
  membersCount: Scalars['Int']['output'];
  membership: MembershipObject;
  memberships: Array<MembershipObject>;
  membershipsCount: Scalars['Int']['output'];
  notificationLogChannels: Array<NotificationChannelObject>;
  notificationLogChannelsCount: Scalars['Int']['output'];
  notificationLogTypes: Array<NotificationTypeObject>;
  notificationLogTypesCount: Scalars['Int']['output'];
  notificationLogs: Array<NotificationLogObject>;
  notificationLogsCount: Scalars['Int']['output'];
  order: OrderObject;
  orderFulfilmentInterval: FulfilmentIntervalObject;
  orderFulfilmentIntervals: Array<FulfilmentIntervalObject>;
  /** Find one order incident by id */
  orderIncident: OrderIncidentObject;
  /** Find many order incident categories */
  orderIncidentCategories: Array<OrderIncidentCategoryObject>;
  /** Count order incident categories */
  orderIncidentCategoriesCount: Scalars['Int']['output'];
  /** Find one order incident category by id */
  orderIncidentCategory: OrderIncidentCategoryObject;
  /** Find many order incidents */
  orderIncidents: Array<OrderIncidentObject>;
  /** Count order incidents */
  orderIncidentsCount: Scalars['Int']['output'];
  orderItem: OrderItemObject;
  orderItems: Array<OrderItemObject>;
  orderItemsCount: Scalars['Int']['output'];
  orderNote: OrderNoteObject;
  orderNotes: Array<OrderNoteObject>;
  orderProcessors: Array<OrderProcessorObject>;
  orderSalesStats: Array<OrderSalesStatsObject>;
  orderSources: Array<OrderSourceObject>;
  orderSourcesCount: Scalars['Int']['output'];
  orderStateCounts: Array<OrderStateCountsObject>;
  orderTag: OrderTagObject;
  orderTags: Array<OrderTagObject>;
  orderTagsCount: Scalars['Int']['output'];
  orderTemplate: OrderTemplateObject;
  orderTemplates: Array<OrderTemplateObject>;
  orderTemplatesCount: Scalars['Float']['output'];
  orders: Array<OrderObject>;
  ordersCount: Scalars['Int']['output'];
  parcelOption: ParcelOptionObject;
  parcelOptions: Array<ParcelOptionObject>;
  parcelOptionsCount: Scalars['Int']['output'];
  payment: PaymentObject;
  paymentErrorDescriptions: Array<Scalars['String']['output']>;
  paymentMethod: PaymentMethodObject;
  paymentMethods: Array<PaymentMethodObject>;
  paymentMethodsCount: Scalars['Int']['output'];
  paymentProviders: Array<PaymentProviderObject>;
  product: ProductObject;
  productAssociation: ProductAssociationObject;
  productAssociationConfigs: ProductAssociationConfigObject;
  productAssociationRecommendationModels: Array<ProductRecommendationModelObject>;
  productAssociations: Array<ProductAssociationObject>;
  productAssociationsCount: Scalars['Int']['output'];
  productAttribute: ProductAttributeObject;
  productAttributes: Array<ProductAttributeObject>;
  productAttributesCount: Scalars['Int']['output'];
  productOption: ProductOptionObject;
  productOptions: Array<ProductOptionObject>;
  productOptionsCount: Scalars['Int']['output'];
  productVariant: ProductVariantObject;
  productVariants: Array<ProductVariantObject>;
  productVariantsCount: Scalars['Int']['output'];
  productWishlistItems: Array<ProductWishlistItemObject>;
  productWishlistItemsCount: Scalars['Int']['output'];
  products: Array<ProductObject>;
  productsCount: Scalars['Int']['output'];
  productsSearch: Array<ProductObject>;
  promotion: PromotionObject;
  promotionActionDescription?: Maybe<Scalars['String']['output']>;
  promotionActions: Array<PromotionActionTypeObject>;
  promotionRuleDescription?: Maybe<Scalars['String']['output']>;
  promotionRules: Array<PromotionRuleTypeObject>;
  promotions: Array<PromotionObject>;
  promotionsCount: Scalars['Int']['output'];
  reconCartPayment: PaymentObject;
  shipment: ShipmentObject;
  shipmentLabelGenerators: Array<ShipmentLabelGeneratorObject>;
  shipmentParcel: ShipmentParcelObject;
  shipments: Array<ShipmentObject>;
  shipmentsCount: Scalars['Int']['output'];
  shippingCategories: Array<ShippingCategoryObject>;
  shippingCategoriesCount: Scalars['Int']['output'];
  shippingCategory: ShippingCategoryObject;
  shippingCostCalculators: Array<ShippingCostCalculatorObject>;
  shippingMethod: ShippingMethodObject;
  shippingMethods: Array<ShippingMethodObject>;
  shippingMethodsCount: Scalars['Int']['output'];
  shippingProviders: Array<ShippingProviderObject>;
  shippingSchedule: ShippingScheduleObject;
  shippingSchedules: Array<ShippingScheduleObject>;
  shippingSchedulesCount: Scalars['Int']['output'];
  shippingSlot: ShippingSlotObject;
  shippingSlots: Array<ShippingSlotObject>;
  shopAds: Array<ShopAdObject>;
  shopAssociatedProducts: Array<ShopProductObject>;
  shopAssociations: Array<ShopProductAssociationObject>;
  /** Get the authenticated user, as per the given token. If the token is not a user, an error is thrown. */
  shopAuthUser: ShopUserObject;
  shopChannel: ShopChannelObject;
  shopChannelRootTaxon: ShopTaxonObject;
  shopChannels: Array<ShopChannelObject>;
  shopCheckVoucherPaymentBalance: ShopVoucherPaymentBalanceObject;
  shopCollectionPoints: Array<ShopCollectionPointObject>;
  shopCollectionPointsCount: Scalars['Int']['output'];
  shopCouponEligibility: ShopOrderCouponEligibilityObject;
  shopMember?: Maybe<ShopMemberObject>;
  shopMembers: Array<ShopMemberObject>;
  shopMembership: ShopMembershipObject;
  shopOrder: ShopOrderObject;
  shopOrderShippingSlots: Array<ShopShippingSlotObject>;
  shopOrders: Array<ShopOrderObject>;
  shopOrdersCount: Scalars['Int']['output'];
  shopPayment: ShopPaymentObject;
  shopPaymentMethods: Array<ShopPaymentMethodObject>;
  shopProduct: ShopProductObject;
  shopProducts: Array<ShopProductObject>;
  shopProductsCount: Scalars['Int']['output'];
  shopProductsSearch: ShopProductSearchObject;
  shopProductsSearchAutocomplete: ShopProductSearchQuerySuggestionObject;
  shopReconCheckoutPayment: ShopPaymentObject;
  shopRegionMatch?: Maybe<ShopGeoRegionObject>;
  shopRegions: Array<ShopGeoRegionObject>;
  shopShippingMethod: ShopShippingMethodObject;
  shopShippingMethods: Array<ShopShippingMethodObject>;
  shopSubscriber: ShopSubscriberObject;
  shopSubscribers: Array<ShopSubscriberObject>;
  shopSubscription: ShopSubscriptionObject;
  shopSubscriptions: Array<ShopSubscriptionObject>;
  shopSuggestChannels: ShopChannelSuggestionObject;
  shopTaxon: ShopTaxonObject;
  shopTaxons: Array<ShopTaxonObject>;
  /** Get a user by id. Must be authenticated as the user, therefore not very useful for customer user-cases. */
  shopUser: ShopUserObject;
  shopUserPaymentMethod: ShopUserPaymentMethodObject;
  shopUserPaymentMethods: Array<ShopUserPaymentMethodObject>;
  shopVoucher: ShopVoucherObject;
  shopVouchers: Array<ShopVoucherObject>;
  shopWallet?: Maybe<ShopWalletObject>;
  shopWallets: Array<ShopWalletObject>;
  shopWishlistItems: Array<ShopProductWishlistItemObject>;
  shopWishlistItemsCount: Scalars['Int']['output'];
  stockAllocationIntervals: Array<StockAllocationIntervalObject>;
  stockAvailabilityInterval: Array<StockItemQuantityIntervalObject>;
  stockCategories: Array<StockCategoryObject>;
  stockCategoriesCount: Scalars['Float']['output'];
  stockCategory: StockCategoryObject;
  stockItem: StockItemObject;
  stockItemComponent: StockItemComponentObject;
  stockItems: Array<StockItemObject>;
  stockItemsCount: Scalars['Float']['output'];
  stockLot: StockLotObject;
  stockLotRef: StockLotObject;
  stockLotRefs: Array<StockLotObject>;
  stockLots: Array<StockLotObject>;
  stockLotsCount: Scalars['Float']['output'];
  stockSchedule: StockScheduleObject;
  subscriber: SubscriberObject;
  subscriberTag: SubscriberTagObject;
  subscriberTags: Array<SubscriberTagObject>;
  subscriberTagsCount: Scalars['Int']['output'];
  subscribers: Array<SubscriberObject>;
  subscribersCount: Scalars['Int']['output'];
  subscription: SubscriptionObject;
  subscriptionEntries: Array<SubscriptionEntryObject>;
  subscriptionEntry: SubscriptionEntryObject;
  subscriptionRun: SubscriptionRunObject;
  subscriptionRuns: Array<SubscriptionRunObject>;
  subscriptionRunsCount: Scalars['Int']['output'];
  subscriptionVariant: SubscriptionVariantObject;
  subscriptionVariantProductQuantities: Array<SubscriptionVariantProductQuantityObject>;
  subscriptionVariantProductQuantitiesCount: Scalars['Int']['output'];
  subscriptionVariantProductQuantity: SubscriptionVariantProductQuantityObject;
  subscriptionVariants: Array<SubscriptionVariantObject>;
  subscriptionVariantsCount: Scalars['Int']['output'];
  subscriptions: Array<SubscriptionObject>;
  subscriptionsCount: Scalars['Int']['output'];
  taxCategories: Array<TaxCategoryObject>;
  taxCategoriesCount: Scalars['Int']['output'];
  taxCategory: TaxCategoryObject;
  taxRate: TaxRateObject;
  taxRates: Array<TaxRateObject>;
  taxRatesCount: Scalars['Int']['output'];
  taxon: TaxonObject;
  taxonProducts: Array<ProductObject>;
  taxonProductsCount: Scalars['Int']['output'];
  taxonTrees: Array<TaxonTreeObject>;
  /** Query Taxons with filtering, pagination and sorting */
  taxons: Array<TaxonObject>;
  taxonsCount: Scalars['Int']['output'];
  user: UserObject;
  userPaymentMethod: UserPaymentMethodObject;
  userPaymentMethods: Array<UserPaymentMethodObject>;
  userPaymentMethodsCount: Scalars['Int']['output'];
  userVariable: VariableObject;
  userVariables: Array<VariableObject>;
  users: Array<UserObject>;
  usersCount: Scalars['Int']['output'];
  voucher: VoucherObject;
  voucherProgram: VoucherProgramObject;
  voucherPrograms: Array<VoucherProgramObject>;
  voucherProgramsCount: Scalars['Int']['output'];
  voucherProviders: Array<VoucherProviderObject>;
  vouchers: Array<VoucherObject>;
  vouchersCount: Scalars['Int']['output'];
  wallet: WalletObject;
  walletTransaction: WalletTransactionObject;
  walletTransactions: Array<WalletTransactionObject>;
  walletTransactionsCount: Scalars['Int']['output'];
  wallets: Array<WalletObject>;
  walletsCount: Scalars['Int']['output'];
  warehouse: WarehouseObject;
  warehouses: Array<WarehouseObject>;
  warehousesCount: Scalars['Float']['output'];
}


export interface QuerySubscriptionEntriesCountArgs {
  filter?: InputMaybe<SubscriptionEntryFilterInput>;
}


export interface QueryAdArgs {
  id: Scalars['Int']['input'];
}


export interface QueryAddressArgs {
  id: Scalars['Int']['input'];
}


export interface QueryAddressesArgs {
  filter?: InputMaybe<AddressFilterInput>;
  page?: PageInput;
  sort?: InputMaybe<SortInput>;
}


export interface QueryAddressesCountArgs {
  filter?: InputMaybe<AddressFilterInput>;
}


export interface QueryAdsArgs {
  filter: AdFilterInput;
  page?: PageInput;
  sort?: InputMaybe<SortInput>;
}


export interface QueryAdsCountArgs {
  filter?: InputMaybe<AdFilterInput>;
}


export interface QueryAgreementArgs {
  id: Scalars['Int']['input'];
}


export interface QueryAgreementDeclarationsArgs {
  filter?: InputMaybe<AgreementDeclarationFilter>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryAgreementDeclarationsCountArgs {
  filter?: InputMaybe<AgreementDeclarationFilter>;
}


export interface QueryAgreementsArgs {
  filter?: InputMaybe<AgreementFilter>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryAgreementsCountArgs {
  filter?: InputMaybe<AgreementFilter>;
}


export interface QueryAssociatedProductArgs {
  id: Scalars['Int']['input'];
}


export interface QueryAssociatedProductsArgs {
  associationId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
}


export interface QueryAuthRoleArgs {
  id: Scalars['Int']['input'];
}


export interface QueryAuthRolesArgs {
  filter?: InputMaybe<AuthRoleFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryAuthRolesCountArgs {
  filter?: InputMaybe<AuthRoleFilterInput>;
}


export interface QueryBrowseMediaArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
}


export interface QueryBrowseMediaFolderArgs {
  path: Scalars['String']['input'];
}


export interface QueryBulkOrderArgs {
  id: Scalars['Int']['input'];
}


export interface QueryBulkOrdersArgs {
  filter?: InputMaybe<BulkOrderFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryBulkOrdersCountArgs {
  filter?: InputMaybe<BulkOrderFilterInput>;
}


export interface QueryChannelArgs {
  id: Scalars['Int']['input'];
}


export interface QueryChannelByCodeArgs {
  code: Scalars['String']['input'];
}


export interface QueryChannelsArgs {
  filter?: InputMaybe<ChannelFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryChannelsCountArgs {
  filter?: InputMaybe<ChannelFilterInput>;
}


export interface QueryCheckCartPaymentBalanceArgs {
  input: RedeemCartPaymentInput;
}


export interface QueryCollectionPointArgs {
  id: Scalars['Int']['input'];
}


export interface QueryCollectionPointsArgs {
  filter?: InputMaybe<CollectionPointFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryCollectionPointsCountArgs {
  filter?: InputMaybe<CollectionPointFilterInput>;
}


export interface QueryContentArgs {
  ref: Scalars['String']['input'];
}


export interface QueryContentsArgs {
  filter: ContentSearchInput;
  page?: PageInput;
}


export interface QueryCouponEligibilityArgs {
  couponCode: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}


export interface QueryFulfilmentArgs {
  id: Scalars['Float']['input'];
}


export interface QueryFulfilmentsArgs {
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryGeoRegionArgs {
  id: Scalars['Int']['input'];
}


export interface QueryGeoRegionAddressMatchArgs {
  address: AddressInput;
}


export interface QueryGeoRegionDefinitionsArgs {
  filter?: InputMaybe<GeoRegionDefinitionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryGeoRegionsArgs {
  filter?: InputMaybe<GeoRegionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryGeoRegionsCountArgs {
  filter?: InputMaybe<GeoRegionFilterInput>;
}


export interface QueryGetMediaArgs {
  id: Scalars['String']['input'];
}


export interface QueryGetMediaUploadUrlArgs {
  input: MediaUploadInput;
}


export interface QueryGlobalVariableArgs {
  key: Scalars['String']['input'];
}


export interface QueryGlobalVariablesArgs {
  prefix: Scalars['String']['input'];
}


export interface QueryMemberArgs {
  id: Scalars['Int']['input'];
}


export interface QueryMembersArgs {
  filter?: InputMaybe<MemberFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryMembersCountArgs {
  filter?: InputMaybe<MemberFilterInput>;
}


export interface QueryMembershipArgs {
  id: Scalars['Int']['input'];
}


export interface QueryMembershipsArgs {
  filter?: InputMaybe<MembershipFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryMembershipsCountArgs {
  filter?: InputMaybe<MembershipFilterInput>;
}


export interface QueryNotificationLogChannelsArgs {
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryNotificationLogTypesArgs {
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryNotificationLogsArgs {
  filter?: InputMaybe<NotificationLogFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryNotificationLogsCountArgs {
  filter?: InputMaybe<NotificationLogFilterInput>;
}


export interface QueryOrderArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderFulfilmentIntervalArgs {
  orderId: Scalars['Int']['input'];
  slotId: Scalars['String']['input'];
}


export interface QueryOrderFulfilmentIntervalsArgs {
  orderId: Scalars['Int']['input'];
  shippingMethodId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryOrderIncidentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderIncidentCategoriesArgs {
  filter?: InputMaybe<OrderIncidentCategoryFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderIncidentCategoriesCountArgs {
  filter?: InputMaybe<OrderIncidentCategoryFilterInput>;
}


export interface QueryOrderIncidentCategoryArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderIncidentsArgs {
  filter?: InputMaybe<OrderIncidentFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderIncidentsCountArgs {
  filter?: InputMaybe<OrderIncidentFilterInput>;
}


export interface QueryOrderItemArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderItemsArgs {
  filter?: InputMaybe<OrderItemFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderItemsCountArgs {
  filter?: InputMaybe<OrderItemFilterInput>;
}


export interface QueryOrderNoteArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderNotesArgs {
  filter?: InputMaybe<OrderNoteFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderSalesStatsArgs {
  filter: OrderFilterInput;
}


export interface QueryOrderSourcesArgs {
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderStateCountsArgs {
  filter: OrderFilterInput;
}


export interface QueryOrderTagArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderTagsArgs {
  filter?: InputMaybe<OrderTagFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderTagsCountArgs {
  filter?: InputMaybe<OrderTagFilterInput>;
}


export interface QueryOrderTemplateArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderTemplatesArgs {
  filter?: InputMaybe<OrderTemplateFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrderTemplatesCountArgs {
  filter?: InputMaybe<OrderTemplateFilterInput>;
}


export interface QueryOrdersArgs {
  filter?: InputMaybe<OrderFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryOrdersCountArgs {
  filter?: InputMaybe<OrderFilterInput>;
}


export interface QueryParcelOptionArgs {
  id: Scalars['Int']['input'];
}


export interface QueryParcelOptionsArgs {
  filter?: InputMaybe<ParcelOptionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryParcelOptionsCountArgs {
  filter?: InputMaybe<ParcelOptionFilterInput>;
}


export interface QueryPaymentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryPaymentErrorDescriptionsArgs {
  filter?: InputMaybe<PaymentErrorFilterInput>;
  page?: InputMaybe<PageInput>;
}


export interface QueryPaymentMethodArgs {
  id: Scalars['Int']['input'];
}


export interface QueryPaymentMethodsArgs {
  filter?: InputMaybe<PaymentMethodFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryPaymentMethodsCountArgs {
  filter?: InputMaybe<PaymentMethodFilterInput>;
}


export interface QueryProductArgs {
  id: Scalars['Int']['input'];
}


export interface QueryProductAssociationArgs {
  id: Scalars['Int']['input'];
}


export interface QueryProductAssociationConfigsArgs {
  associationId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
}


export interface QueryProductAssociationsArgs {
  filter?: InputMaybe<ProductAssociationFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryProductAssociationsCountArgs {
  filter?: InputMaybe<ProductAssociationFilterInput>;
}


export interface QueryProductAttributeArgs {
  id: Scalars['Int']['input'];
}


export interface QueryProductAttributesArgs {
  filter?: InputMaybe<ProductAttributeFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryProductAttributesCountArgs {
  filter?: InputMaybe<ProductAttributeFilterInput>;
}


export interface QueryProductOptionArgs {
  id: Scalars['Int']['input'];
}


export interface QueryProductOptionsArgs {
  filter?: InputMaybe<ProductOptionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryProductOptionsCountArgs {
  filter?: InputMaybe<ProductOptionFilterInput>;
}


export interface QueryProductVariantArgs {
  id: Scalars['Int']['input'];
}


export interface QueryProductVariantsArgs {
  filter?: InputMaybe<ProductVariantFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryProductVariantsCountArgs {
  filter?: InputMaybe<ProductVariantFilterInput>;
}


export interface QueryProductWishlistItemsArgs {
  filter?: InputMaybe<ProductWishlistItemFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryProductWishlistItemsCountArgs {
  filter?: InputMaybe<ProductWishlistItemFilterInput>;
}


export interface QueryProductsArgs {
  filter?: InputMaybe<ProductFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryProductsCountArgs {
  filter?: InputMaybe<ProductFilterInput>;
}


export interface QueryProductsSearchArgs {
  text: Scalars['String']['input'];
}


export interface QueryPromotionArgs {
  id: Scalars['Int']['input'];
}


export interface QueryPromotionActionDescriptionArgs {
  actionId: Scalars['String']['input'];
  configuration: Scalars['JSON']['input'];
}


export interface QueryPromotionRuleDescriptionArgs {
  configuration: Scalars['JSON']['input'];
  ruleId: Scalars['String']['input'];
}


export interface QueryPromotionsArgs {
  filter?: InputMaybe<PromotionFilterInput>;
  page?: PageInput;
  sort?: InputMaybe<SortInput>;
}


export interface QueryPromotionsCountArgs {
  filter?: InputMaybe<PromotionFilterInput>;
}


export interface QueryReconCartPaymentArgs {
  input: ReconCartPaymentInput;
}


export interface QueryShipmentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShipmentParcelArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShipmentsArgs {
  filter?: InputMaybe<ShipmentFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryShipmentsCountArgs {
  filter?: InputMaybe<ShipmentFilterInput>;
}


export interface QueryShippingCategoriesArgs {
  filter?: InputMaybe<ShippingCategoryFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryShippingCategoriesCountArgs {
  filter?: InputMaybe<ShippingCategoryFilterInput>;
}


export interface QueryShippingCategoryArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShippingMethodArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShippingMethodsArgs {
  filter?: InputMaybe<ShippingMethodFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryShippingMethodsCountArgs {
  filter?: InputMaybe<ShippingMethodFilterInput>;
}


export interface QueryShippingScheduleArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShippingSchedulesArgs {
  filter?: InputMaybe<ShippingScheduleFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryShippingSchedulesCountArgs {
  filter?: InputMaybe<ShippingScheduleFilterInput>;
}


export interface QueryShippingSlotArgs {
  id: Scalars['String']['input'];
}


export interface QueryShippingSlotsArgs {
  filter: ShippingSlotFilterInput;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryShopAdsArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopAssociatedProductsArgs {
  associationId: Scalars['Int']['input'];
  channelCode: Scalars['String']['input'];
  excludeProductIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<ShopPageInput>;
  productIds: Array<Scalars['Int']['input']>;
  recommendation?: InputMaybe<Scalars['Boolean']['input']>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopAssociationsArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  productIds: Array<Scalars['Int']['input']>;
  target: ProductAssociationTarget;
  testCases?: InputMaybe<Array<Scalars['String']['input']>>;
}


export interface QueryShopChannelArgs {
  code: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopChannelRootTaxonArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopChannelsArgs {
  locale: Scalars['String']['input'];
}


export interface QueryShopCheckVoucherPaymentBalanceArgs {
  input: ShopRedeemCartPaymentInput;
}


export interface QueryShopCollectionPointsArgs {
  channelCode: Scalars['String']['input'];
  coordinates?: InputMaybe<ShopPointInput>;
  page?: InputMaybe<ShopPageInput>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  shippingMethodId?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopSortInput>;
}


export interface QueryShopCollectionPointsCountArgs {
  channelCode: Scalars['String']['input'];
  searchtext?: InputMaybe<Scalars['String']['input']>;
  shippingMethodId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopCouponEligibilityArgs {
  couponCode: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}


export interface QueryShopMemberArgs {
  channelCode: Scalars['String']['input'];
  membershipId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}


export interface QueryShopMembersArgs {
  channelCode: Scalars['String']['input'];
  membershipId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
}


export interface QueryShopMembershipArgs {
  channelCode: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopOrderArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopOrderShippingSlotsArgs {
  orderId: Scalars['Int']['input'];
  showUnavailable?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface QueryShopOrdersArgs {
  page?: InputMaybe<ShopPageInput>;
  sort?: InputMaybe<ShopSortInput>;
  states?: InputMaybe<Array<OrderState>>;
  userId: Scalars['Int']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopOrdersCountArgs {
  states?: InputMaybe<Array<OrderState>>;
  userId: Scalars['Int']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopPaymentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopPaymentMethodsArgs {
  channelCode: Scalars['String']['input'];
}


export interface QueryShopProductArgs {
  channelCode: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopProductsArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  page?: InputMaybe<ShopPageInput>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopSortInput>;
  taxonId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopProductsCountArgs {
  channelCode: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
  taxonId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopProductsSearchArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  page?: InputMaybe<ShopPageInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  searchText: Scalars['String']['input'];
  taxonId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopProductsSearchAutocompleteArgs {
  page?: InputMaybe<ShopPageInput>;
  searchText: Scalars['String']['input'];
}


export interface QueryShopReconCheckoutPaymentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopRegionMatchArgs {
  address: ShopAddressInput;
}


export interface QueryShopRegionsArgs {
  channelCode?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryShopShippingMethodArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopShippingMethodsArgs {
  channelCode?: InputMaybe<Scalars['String']['input']>;
  collection?: InputMaybe<Scalars['Boolean']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopSubscriberArgs {
  subscriberId: Scalars['Int']['input'];
}


export interface QueryShopSubscribersArgs {
  channelCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
}


export interface QueryShopSubscriptionArgs {
  channelCode: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopSubscriptionsArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}


export interface QueryShopSuggestChannelsArgs {
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
}


export interface QueryShopTaxonArgs {
  channelCode: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopTaxonsArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopUserArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopUserPaymentMethodArgs {
  id: Scalars['Int']['input'];
}


export interface QueryShopUserPaymentMethodsArgs {
  states?: InputMaybe<Array<UserPaymentMethodState>>;
  userId: Scalars['Int']['input'];
}


export interface QueryShopVoucherArgs {
  voucherId: Scalars['Int']['input'];
}


export interface QueryShopVouchersArgs {
  userId: Scalars['Int']['input'];
}


export interface QueryShopWalletArgs {
  currency: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
}


export interface QueryShopWalletsArgs {
  userId: Scalars['Int']['input'];
}


export interface QueryShopWishlistItemsArgs {
  channelCode: Scalars['String']['input'];
  page?: InputMaybe<ShopPageInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopSortInput>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryShopWishlistItemsCountArgs {
  channelCode: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryStockAllocationIntervalsArgs {
  filter: StockAllocationIntervalFilterInput;
}


export interface QueryStockAvailabilityIntervalArgs {
  filter: StockAvailabilityIntervalFilterInput;
}


export interface QueryStockCategoriesArgs {
  filter?: InputMaybe<StockCategoryFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryStockCategoriesCountArgs {
  filter?: InputMaybe<StockCategoryFilterInput>;
}


export interface QueryStockCategoryArgs {
  id: Scalars['Float']['input'];
}


export interface QueryStockItemArgs {
  id: Scalars['Int']['input'];
}


export interface QueryStockItemComponentArgs {
  id: Scalars['Int']['input'];
}


export interface QueryStockItemsArgs {
  filter?: InputMaybe<StockItemFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryStockItemsCountArgs {
  filter?: InputMaybe<StockItemFilterInput>;
}


export interface QueryStockLotArgs {
  id: Scalars['String']['input'];
}


export interface QueryStockLotRefArgs {
  id: Scalars['String']['input'];
}


export interface QueryStockLotRefsArgs {
  filter: StockLotRefFilterInput;
}


export interface QueryStockLotsArgs {
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryStockScheduleArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriberArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriberTagArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriberTagsArgs {
  filter?: InputMaybe<SubscriberTagFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QuerySubscriberTagsCountArgs {
  filter?: InputMaybe<SubscriberTagFilterInput>;
}


export interface QuerySubscribersArgs {
  filter?: InputMaybe<SubscriberFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscribersCountArgs {
  filter?: InputMaybe<SubscriberFilterInput>;
}


export interface QuerySubscriptionArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriptionEntriesArgs {
  filter?: InputMaybe<SubscriptionEntryFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscriptionEntryArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriptionRunArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriptionRunsArgs {
  filter?: InputMaybe<SubscriptionRunFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscriptionRunsCountArgs {
  filter?: InputMaybe<SubscriptionRunFilterInput>;
}


export interface QuerySubscriptionVariantArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriptionVariantProductQuantitiesArgs {
  filter?: InputMaybe<SubscriptionVariantProductQuantityFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscriptionVariantProductQuantitiesCountArgs {
  filter?: InputMaybe<SubscriptionVariantProductQuantityFilterInput>;
}


export interface QuerySubscriptionVariantProductQuantityArgs {
  id: Scalars['Int']['input'];
}


export interface QuerySubscriptionVariantsArgs {
  filter?: InputMaybe<SubscriptionVariantFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscriptionVariantsCountArgs {
  filter?: InputMaybe<SubscriptionVariantFilterInput>;
}


export interface QuerySubscriptionsArgs {
  filter?: InputMaybe<SubscriptionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QuerySubscriptionsCountArgs {
  filter?: InputMaybe<SubscriptionFilterInput>;
}


export interface QueryTaxCategoriesArgs {
  filter?: InputMaybe<TaxCategoryFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryTaxCategoriesCountArgs {
  filter?: InputMaybe<TaxCategoryFilterInput>;
}


export interface QueryTaxCategoryArgs {
  id: Scalars['Int']['input'];
}


export interface QueryTaxRateArgs {
  id: Scalars['Int']['input'];
}


export interface QueryTaxRatesArgs {
  filter?: InputMaybe<TaxRateFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryTaxRatesCountArgs {
  filter?: InputMaybe<TaxRateFilterInput>;
}


export interface QueryTaxonArgs {
  id: Scalars['Int']['input'];
}


export interface QueryTaxonProductsArgs {
  filter: TaxonProductFilterInput;
  page?: PageInput;
}


export interface QueryTaxonProductsCountArgs {
  filter: TaxonProductFilterInput;
}


export interface QueryTaxonsArgs {
  filter?: InputMaybe<TaxonFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: InputMaybe<SortInput>;
}


export interface QueryTaxonsCountArgs {
  filter?: InputMaybe<TaxonFilterInput>;
}


export interface QueryUserArgs {
  id: Scalars['Int']['input'];
}


export interface QueryUserPaymentMethodArgs {
  id: Scalars['Int']['input'];
}


export interface QueryUserPaymentMethodsArgs {
  filter?: InputMaybe<UserPaymentMethodFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryUserPaymentMethodsCountArgs {
  filter?: InputMaybe<UserPaymentMethodFilterInput>;
}


export interface QueryUserVariableArgs {
  key: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
}


export interface QueryUserVariablesArgs {
  prefix: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
}


export interface QueryUsersArgs {
  filter?: InputMaybe<UserFilterInput>;
  page?: PageInput;
  sort?: InputMaybe<SortInput>;
}


export interface QueryUsersCountArgs {
  filter?: InputMaybe<UserFilterInput>;
}


export interface QueryVoucherArgs {
  id: Scalars['Int']['input'];
}


export interface QueryVoucherProgramArgs {
  id: Scalars['Int']['input'];
}


export interface QueryVoucherProgramsArgs {
  filter?: InputMaybe<VoucherProgramFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryVoucherProgramsCountArgs {
  filter?: InputMaybe<VoucherProgramFilterInput>;
}


export interface QueryVouchersArgs {
  filter?: InputMaybe<VoucherFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryVouchersCountArgs {
  filter?: InputMaybe<VoucherFilterInput>;
}


export interface QueryWalletArgs {
  id: Scalars['Int']['input'];
}


export interface QueryWalletTransactionArgs {
  id: Scalars['Int']['input'];
}


export interface QueryWalletTransactionsArgs {
  filter?: InputMaybe<WalletTransactionFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryWalletTransactionsCountArgs {
  filter?: InputMaybe<WalletTransactionFilterInput>;
}


export interface QueryWalletsArgs {
  filter?: InputMaybe<WalletFilterInput>;
  page?: InputMaybe<PageInput>;
  sort?: SortInput;
}


export interface QueryWalletsCountArgs {
  filter?: InputMaybe<WalletFilterInput>;
}


export interface QueryWarehouseArgs {
  id: Scalars['Int']['input'];
}


export interface QueryWarehousesArgs {
  filter?: InputMaybe<WarehouseFilterInput>;
  page?: PageInput;
  sort?: SortInput;
}


export interface QueryWarehousesCountArgs {
  filter?: InputMaybe<WarehouseFilterInput>;
}

export interface ReconCartPaymentInput {
  paymentId: Scalars['Int']['input'];
}

export interface ReconShipmentInput {
  id: Scalars['Int']['input'];
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface RedeemCartPaymentInput {
  code: Scalars['String']['input'];
  paymentId: Scalars['Int']['input'];
}

export interface RefundCartPaymentInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  note: Scalars['String']['input'];
  paymentId: Scalars['Int']['input'];
}

export interface RefundOrderToWalletInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface RegistrationInput {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export enum RelativeDateType {
  DayAfterTomorrow = 'DAY_AFTER_TOMORROW',
  LastWeek = 'LAST_WEEK',
  LastYear = 'LAST_YEAR',
  NextWeek = 'NEXT_WEEK',
  NextYear = 'NEXT_YEAR',
  ThisWeek = 'THIS_WEEK',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  Yesterday = 'YESTERDAY'
}

export interface RemoveCartItemInput {
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
}

export interface RemoveOrderItemInput {
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
}

export interface RemoveProductTaxonInput {
  id: Scalars['Int']['input'];
  taxonIds: Array<Scalars['Int']['input']>;
}

export interface ReportObject {
  __typename?: 'ReportObject';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ReportProductAvailabilityInput {
  channelId: Scalars['Int']['input'];
  format?: InputMaybe<Scalars['String']['input']>;
  productFilter: ProductFilterInput;
  regionId: Scalars['Int']['input'];
}

export interface ReportProductInput {
  format?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  options?: InputMaybe<ProductReportOptionsInput>;
  productFilter: ProductFilterInput;
}

export interface RequestCartPaymentInput {
  orderId: Scalars['Int']['input'];
  userPaymentMethodId: Scalars['Int']['input'];
}

export interface RequestNewCartPaymentInput {
  isExpressCheckout?: Scalars['Boolean']['input'];
  orderId: Scalars['Int']['input'];
  paymentMethodId: Scalars['Int']['input'];
  save?: Scalars['Boolean']['input'];
}

export interface RequestOrderShipmentSlotInput {
  methodId?: InputMaybe<Scalars['Int']['input']>;
  orderId: Scalars['Int']['input'];
  reschedule?: InputMaybe<Scalars['Boolean']['input']>;
  slotId: Scalars['String']['input'];
}

export interface RescheduleOrderShipmentInput {
  methodId?: InputMaybe<Scalars['Int']['input']>;
  orderId: Scalars['Int']['input'];
  slotId?: InputMaybe<Scalars['String']['input']>;
}

export interface RescheduleShipmentInput {
  id: Scalars['Int']['input'];
  methodId?: InputMaybe<Scalars['Int']['input']>;
  slotId?: InputMaybe<Scalars['String']['input']>;
}

export interface ResetPasswordInput {
  password: Scalars['String']['input'];
}

export interface ResetUserPasswordInput {
  newPassword: Scalars['String']['input'];
  userId: Scalars['Float']['input'];
}

export interface SetGlobalVariableInput {
  key: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
}

export interface SetOrderShipmentFixedCostInput {
  cost?: InputMaybe<Scalars['Int']['input']>;
  orderId: Scalars['Int']['input'];
}

export interface SetProductPositionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  taxonId: Scalars['Int']['input'];
}

export interface SetUserVariableInput {
  key: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
  value: Scalars['JSON']['input'];
}

export interface ShipmentFilterInput {
  checkoutAt?: InputMaybe<DateRangeFilterInput>;
  collectionPointIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderStates?: InputMaybe<Array<OrderState>>;
  relativeCheckoutAt?: InputMaybe<RelativeDateType>;
  relativeShippingSlotAt?: InputMaybe<RelativeDateType>;
  requestedSlotStartAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  shipmentStates?: InputMaybe<Array<ShipmentState>>;
  shippingAddressGeoRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategories?: InputMaybe<OrderShippingCategoryFilterInput>;
  shippingMethodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingSlotAt?: InputMaybe<DateRangeFilterInput>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ShipmentLabelGeneratorObject {
  __typename?: 'ShipmentLabelGeneratorObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ShipmentObject {
  __typename?: 'ShipmentObject';
  assignedSlotId?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  completedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  driver?: Maybe<Scalars['String']['output']>;
  errorDescription?: Maybe<Scalars['String']['output']>;
  fixedCost?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  labeledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  method?: Maybe<ShippingMethodObject>;
  methodLocked: Scalars['Boolean']['output'];
  order: OrderObject;
  parcels: Array<ShipmentParcelObject>;
  readyAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  requestedSlotEndAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requestedSlotId?: Maybe<Scalars['String']['output']>;
  requestedSlotStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requiredAddressType: AddressType;
  reservedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  routeSequence?: Maybe<Scalars['Int']['output']>;
  serviceOption?: Maybe<Scalars['String']['output']>;
  shippedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  slot?: Maybe<ShippingSlotObject>;
  slotLocked: Scalars['Boolean']['output'];
  state: ShipmentState;
  totalWeight: Scalars['Int']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShipmentParcelObject {
  __typename?: 'ShipmentParcelObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  dimensions?: Maybe<DimensionsObject>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  option?: Maybe<ParcelOptionObject>;
  reference?: Maybe<Scalars['String']['output']>;
  shipment: ShipmentObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
}

export enum ShipmentState {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  New = 'NEW',
  Pending = 'PENDING',
  Reserved = 'RESERVED',
  Shipped = 'SHIPPED',
  WaybillRequired = 'WAYBILL_REQUIRED'
}

export interface ShipmentStateCountObject {
  __typename?: 'ShipmentStateCountObject';
  count: Scalars['Int']['output'];
  state: ShipmentState;
}

export enum ShipmentStateTransition {
  Cancel = 'cancel',
  Deliver = 'deliver',
  Ready = 'ready',
  RequestWaybill = 'requestWaybill',
  Reserve = 'reserve',
  Ship = 'ship'
}

export enum ShippingCategoryComparison {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
  IncludeOrNone = 'INCLUDE_OR_NONE'
}

export interface ShippingCategoryFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ShippingCategoryObject {
  __typename?: 'ShippingCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShippingCostCalculatorObject {
  __typename?: 'ShippingCostCalculatorObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ShippingMethodChannelEnabledInput {
  channelId: Scalars['Int']['input'];
  enable?: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
}

export interface ShippingMethodFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabledChannelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  slotRequired?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ShippingMethodObject {
  __typename?: 'ShippingMethodObject';
  categories: Array<ShippingCategoryObject>;
  categoryComparison: ShippingCategoryComparison;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  collectionPoints: Array<CollectionPointObject>;
  collectionRequired: Scalars['Boolean']['output'];
  color?: Maybe<Scalars['String']['output']>;
  costCalculator?: Maybe<Scalars['String']['output']>;
  costCalculatorConfig?: Maybe<Scalars['JSON']['output']>;
  costProductVariant?: Maybe<ProductVariantObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  groupDestination: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  labelGenerator?: Maybe<Scalars['String']['output']>;
  labelGeneratorConfig?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  provider: Scalars['String']['output'];
  providerConfig?: Maybe<Scalars['JSON']['output']>;
  quoteValidityDuration?: Maybe<Scalars['Duration']['output']>;
  regions: Array<GeoRegionObject>;
  requiresWaybill: Scalars['Boolean']['output'];
  schedules: Array<ShippingScheduleObject>;
  serviceOptions: Array<ShippingServiceOptionObject>;
  slotRequired: Scalars['Boolean']['output'];
  slots: Array<ShippingSlotObject>;
  stockType: StockType;
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
  warehouses: Array<ShippingWarehouseObject>;
}

export interface ShippingProviderObject {
  __typename?: 'ShippingProviderObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ShippingScheduleFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface ShippingScheduleObject {
  __typename?: 'ShippingScheduleObject';
  capacity: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  daysOfWeek?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Duration']['output'];
  enabled: Scalars['Boolean']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  lagTime?: Maybe<Scalars['Duration']['output']>;
  leadTime?: Maybe<Scalars['Duration']['output']>;
  method: ShippingMethodObject;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  regionIds?: Maybe<Scalars['JSON']['output']>;
  regions?: Maybe<Array<GeoRegionObject>>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  startTime: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShippingServiceOptionObject {
  __typename?: 'ShippingServiceOptionObject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface ShippingSlotAvailabilityObject {
  __typename?: 'ShippingSlotAvailabilityObject';
  availableCapacity: Scalars['Int']['output'];
  groupingAvailable?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  slotEndAt: Scalars['DateTimeScalar']['output'];
  slotId: Scalars['String']['output'];
  slotStartAt: Scalars['DateTimeScalar']['output'];
  unavailableReason?: Maybe<Scalars['String']['output']>;
  usedCapacity: Scalars['Int']['output'];
}

export interface ShippingSlotFilterInput {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  methodId?: InputMaybe<Scalars['Int']['input']>;
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  scheduleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  startAtRange?: InputMaybe<ChronoLogicRangeInput>;
}

export interface ShippingSlotObject {
  __typename?: 'ShippingSlotObject';
  availability: ShippingSlotAvailabilityObject;
  availableFrom?: Maybe<Scalars['DateTimeScalar']['output']>;
  availableTo?: Maybe<Scalars['DateTimeScalar']['output']>;
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  endAt: Scalars['DateTimeScalar']['output'];
  fulfilmentInterval?: Maybe<FulfilmentIntervalObject>;
  id: Scalars['String']['output'];
  lagTime?: Maybe<Scalars['Duration']['output']>;
  leadTime?: Maybe<Scalars['Duration']['output']>;
  method: ShippingMethodObject;
  overrides?: Maybe<Array<Scalars['String']['output']>>;
  priority: Scalars['Int']['output'];
  regionIds?: Maybe<Scalars['JSON']['output']>;
  regions?: Maybe<Array<GeoRegionObject>>;
  schedule?: Maybe<ShippingScheduleObject>;
  shipmentStateCounts: Array<ShipmentStateCountObject>;
  startAt: Scalars['DateTimeScalar']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface ShippingSlotObjectFulfilmentIntervalArgs {
  orderId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ShippingWarehouseObject {
  __typename?: 'ShippingWarehouseObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface ShopActivateMemberInput {
  memberId: Scalars['Int']['input'];
}

export interface ShopActivateSubscriberInput {
  subscriberId: Scalars['Int']['input'];
}

export interface ShopAdMediaObject {
  __typename?: 'ShopAdMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  mediaQuery: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface ShopAdObject {
  __typename?: 'ShopAdObject';
  id: Scalars['Int']['output'];
  media: Array<ShopAdMediaObject>;
  name: Scalars['String']['output'];
  targetAuthorizationType: Scalars['String']['output'];
  targetChildrenTaxons: Scalars['Boolean']['output'];
  targetRegions: Array<ShopTaxonObject>;
  targetTaxons: Array<ShopTaxonObject>;
  url: Scalars['String']['output'];
  urlTarget: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
}

export interface ShopAddressInput {
  alias?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  complex?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<PointInput>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AddressType>;
}

export interface ShopAddressObject {
  __typename?: 'ShopAddressObject';
  alias?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  complex?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['Point']['output']>;
  country: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isDefaultBillingAddress?: Maybe<Scalars['Boolean']['output']>;
  isDefaultShippingAddress?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  recipientHash?: Maybe<Scalars['String']['output']>;
  region?: Maybe<ShopGeoRegionObject>;
  street?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  type: AddressType;
}

export interface ShopCancelCartPaymentInput {
  paymentId: Scalars['Int']['input'];
}

export interface ShopCartCreateResultObject {
  __typename?: 'ShopCartCreateResultObject';
  auth: ShopOrderAuthenticationObject;
  order: ShopOrderObject;
}

export interface ShopCartItemContextInput {
  associationId?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  queryId?: InputMaybe<Scalars['String']['input']>;
  queryIndex?: InputMaybe<Scalars['String']['input']>;
  shopAdId?: InputMaybe<Scalars['Int']['input']>;
  shopAdPlacement?: InputMaybe<Scalars['String']['input']>;
  taxonId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ShopChannelObject {
  __typename?: 'ShopChannelObject';
  code: Scalars['String']['output'];
  contentRef?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  defaultCollectionPoint?: Maybe<ShopCollectionPointObject>;
  defaultCollectionPointId?: Maybe<Scalars['Int']['output']>;
  defaultCountry?: Maybe<Scalars['String']['output']>;
  defaultLocale: Scalars['String']['output'];
  enquiryOnly: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  locales: Array<Scalars['String']['output']>;
  meta?: Maybe<ShopMetaContentObject>;
  name: Scalars['String']['output'];
  regions: Array<ShopGeoRegionObject>;
  rootTaxon: ShopTaxonObject;
  rootTaxonId?: Maybe<Scalars['Int']['output']>;
}

export interface ShopChannelSuggestionObject {
  __typename?: 'ShopChannelSuggestionObject';
  defaultChannel?: Maybe<ShopChannelObject>;
  preferredChannel?: Maybe<ShopChannelObject>;
  resolvedRegion?: Maybe<ShopGeoRegionObject>;
  usableChannel?: Maybe<ShopChannelObject>;
}

export interface ShopCheckoutCartInput {
  orderId: Scalars['Int']['input'];
}

export interface ShopCheckoutRequirementObject {
  __typename?: 'ShopCheckoutRequirementObject';
  code: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export interface ShopCollectionPointObject {
  __typename?: 'ShopCollectionPointObject';
  address: ShopAddressObject;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ShopCreateCartInput {
  address?: InputMaybe<AddressInput>;
  addressAssignment?: InputMaybe<AddressAssignment>;
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ShopCreateCartPaymentInput {
  orderId: Scalars['Int']['input'];
  userPaymentMethodId: Scalars['Int']['input'];
}

export interface ShopCreateCartTokenInput {
  orderId: Scalars['Int']['input'];
}

export interface ShopCreateMemberInput {
  channelCode: Scalars['String']['input'];
  membershipId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopCreateNewCartPaymentInput {
  isExpressCheckout?: Scalars['Boolean']['input'];
  orderId: Scalars['Int']['input'];
  paymentMethodId: Scalars['Int']['input'];
  save?: Scalars['Boolean']['input'];
}

export interface ShopCreateSubscriberInput {
  locale: Scalars['String']['input'];
  subscriptionVariantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopCreateUserAddressInput {
  address: ShopAddressInput;
  setAsDefaultBillingAddress?: Scalars['Boolean']['input'];
  setAsDefaultShippingAddress?: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopCreateUserPaymentMethodInput {
  paymentMethodId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopCreateWalletPaymentInput {
  orderId: Scalars['Int']['input'];
}

export interface ShopCurrencyObject {
  __typename?: 'ShopCurrencyObject';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface ShopDeactivateMemberInput {
  memberId: Scalars['Int']['input'];
}

export interface ShopDeleteCartItemInput {
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
}

export interface ShopDeleteUserAddressInput {
  addressId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopEnquireCartInput {
  orderId: Scalars['Int']['input'];
}

export interface ShopExportOrderInvoiceObject {
  __typename?: 'ShopExportOrderInvoiceObject';
  url: Scalars['String']['output'];
}

export interface ShopGeoRegionDefinition {
  __typename?: 'ShopGeoRegionDefinition';
  boundary?: Maybe<Scalars['Polygon']['output']>;
  center?: Maybe<Scalars['Point']['output']>;
  id: Scalars['Int']['output'];
}

export interface ShopGeoRegionObject {
  __typename?: 'ShopGeoRegionObject';
  channelCode: Scalars['String']['output'];
  channelId: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  definitions: Array<ShopGeoRegionDefinition>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
}

export interface ShopMemberObject {
  __typename?: 'ShopMemberObject';
  channelCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  membership?: Maybe<ShopMembershipObject>;
  state: MemberState;
}


export interface ShopMemberObjectMembershipArgs {
  locale: Scalars['String']['input'];
}

export interface ShopMembershipObject {
  __typename?: 'ShopMembershipObject';
  activationUrl?: Maybe<Scalars['String']['output']>;
  contentRef?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta?: Maybe<ShopMetaContentObject>;
  name: Scalars['String']['output'];
  renewalMessage?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
}

export interface ShopMetaContentObject {
  __typename?: 'ShopMetaContentObject';
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ShopMutationResult {
  __typename?: 'ShopMutationResult';
  id: Scalars['Int']['output'];
}

export interface ShopOrderAdjustmentObject {
  __typename?: 'ShopOrderAdjustmentObject';
  amount: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  orderItemId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
}

export interface ShopOrderAgreementObject {
  __typename?: 'ShopOrderAgreementObject';
  agreed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  termsUrls?: Maybe<Scalars['String']['output']>;
}

export interface ShopOrderAuthenticationObject {
  __typename?: 'ShopOrderAuthenticationObject';
  channelCode: Scalars['String']['output'];
  expiresAt: Scalars['DateTimeScalar']['output'];
  issuedAt: Scalars['DateTimeScalar']['output'];
  orderId: Scalars['Int']['output'];
  token: Scalars['String']['output'];
}

export interface ShopOrderCheckoutRequirementsObject {
  __typename?: 'ShopOrderCheckoutRequirementsObject';
  canCheckout: Scalars['Boolean']['output'];
  requirements: Array<ShopCheckoutRequirementObject>;
}

export interface ShopOrderCouponEligibilityObject {
  __typename?: 'ShopOrderCouponEligibilityObject';
  isEligible: Scalars['Boolean']['output'];
  uneligibleReasonCode?: Maybe<Scalars['String']['output']>;
}

export interface ShopOrderItemAdjustmentObject {
  __typename?: 'ShopOrderItemAdjustmentObject';
  amount: Scalars['Int']['output'];
  display?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  orderItemId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
}

export interface ShopOrderItemAvailabilityObject {
  __typename?: 'ShopOrderItemAvailabilityObject';
  isAvailable: Scalars['Boolean']['output'];
  nextAvailableDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  nextAvailableQuantity?: Maybe<Scalars['Int']['output']>;
  unavailableReason?: Maybe<ShopProductUnavailableReason>;
}

export interface ShopOrderItemContextObject {
  __typename?: 'ShopOrderItemContextObject';
  associationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  objectId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  queryId?: Maybe<Scalars['String']['output']>;
  queryIndex?: Maybe<Scalars['String']['output']>;
  shopAdId?: Maybe<Scalars['Int']['output']>;
  shopAdPlacement?: Maybe<Scalars['String']['output']>;
  taxonId?: Maybe<Scalars['Int']['output']>;
}

export interface ShopOrderItemObject {
  __typename?: 'ShopOrderItemObject';
  adjustments: Array<ShopOrderItemAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  availability?: Maybe<ShopOrderItemAvailabilityObject>;
  availableRegions: Array<ShopGeoRegionObject>;
  context?: Maybe<ShopOrderItemContextObject>;
  id: Scalars['Int']['output'];
  media: ShopProductMediaObject;
  productHidden: Scalars['Boolean']['output'];
  productId: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  source: OrderItemSource;
  taxAmount: Scalars['Int']['output'];
  taxRate: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
  unitsTotal: Scalars['Int']['output'];
  variantId: Scalars['Int']['output'];
  variantName?: Maybe<Scalars['String']['output']>;
}

export interface ShopOrderObject {
  __typename?: 'ShopOrderObject';
  adjustments: Array<ShopOrderAdjustmentObject>;
  adjustmentsTotal: Scalars['Int']['output'];
  availableShippingMethods: Array<ShopShippingMethodObject>;
  billingAddress?: Maybe<ShopAddressObject>;
  channelCode: Scalars['String']['output'];
  checkoutAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  checkoutRequirements?: Maybe<ShopOrderCheckoutRequirementsObject>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customerNthOrder?: Maybe<Scalars['Int']['output']>;
  giftMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isGift: Scalars['Boolean']['output'];
  items: Array<ShopOrderItemObject>;
  itemsTotal: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  outstandingTotal: Scalars['Float']['output'];
  paidTotal: Scalars['Int']['output'];
  payments: Array<ShopPaymentObject>;
  promotions: Array<ShopPromotionObject>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  refundedTotal: Scalars['Int']['output'];
  requestedShippingMethod?: Maybe<ShopShippingMethodObject>;
  requiredAgreements: Array<ShopOrderAgreementObject>;
  shipment?: Maybe<ShopShipmentObject>;
  shippingAddress?: Maybe<ShopAddressObject>;
  shippingAddressRegion?: Maybe<ShopGeoRegionObject>;
  shippingMethod?: Maybe<ShopShippingMethodObject>;
  shippingTotal: Scalars['Int']['output'];
  state: OrderState;
  subscriber?: Maybe<ShopSubscriberObject>;
  subtotal: Scalars['Float']['output'];
  taxTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
  walletTransactions: Array<ShopWalletTransactionObject>;
}


export interface ShopOrderObjectRequiredAgreementsArgs {
  locale: Scalars['String']['input'];
}

export interface ShopPageInput {
  index?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}

export interface ShopPauseSubscriberInput {
  subscriberId: Scalars['Int']['input'];
}

export interface ShopPaymentMethodObject {
  __typename?: 'ShopPaymentMethodObject';
  canReuse: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  expressCheckoutEnabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  publicAttributes?: Maybe<ShopPaymentMethodPublicAttributesUnion>;
}

export type ShopPaymentMethodPublicAttributesUnion = StripePublicClientAttributes;

export interface ShopPaymentObject {
  __typename?: 'ShopPaymentObject';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  method: ShopPaymentMethodObject;
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  reference: Scalars['String']['output'];
  /** @deprecated Use reference instead */
  referenceNumber: Scalars['String']['output'];
  state: PaymentState;
}

export interface ShopPointInput {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
}

export interface ShopProcessCartInput {
  orderId: Scalars['Int']['input'];
}

export interface ShopProductAssociationObject {
  __typename?: 'ShopProductAssociationObject';
  id: Scalars['Int']['output'];
  products: Array<ShopProductObject>;
  title: Scalars['String']['output'];
}


export interface ShopProductAssociationObjectProductsArgs {
  channelCode: Scalars['String']['input'];
  excludeProductIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  locale: Scalars['String']['input'];
  page?: InputMaybe<ShopPageInput>;
  productIds: Array<Scalars['Int']['input']>;
  recommendation?: InputMaybe<Scalars['Boolean']['input']>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ShopProductAttributeObject {
  __typename?: 'ShopProductAttributeObject';
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  type: ProductAttributeType;
  value: Scalars['String']['output'];
}

export interface ShopProductMediaObject {
  __typename?: 'ShopProductMediaObject';
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  variantId?: Maybe<Scalars['Int']['output']>;
}

export interface ShopProductObject {
  __typename?: 'ShopProductObject';
  associations: Array<ShopProductAssociationObject>;
  attributes: Array<ShopProductAttributeObject>;
  badges: Array<ShopTaxonBadgeObject>;
  channelCode: Scalars['String']['output'];
  contentRef?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  mainMedia?: Maybe<ShopProductMediaObject>;
  mainTaxon: ShopTaxonObject;
  mainTaxonId: Scalars['Int']['output'];
  media: Array<ShopProductMediaObject>;
  membershipRequirements: Array<ShopMembershipObject>;
  meta: ShopMetaContentObject;
  name: Scalars['String']['output'];
  options: Array<ShopProductOptionObject>;
  priority: Scalars['Int']['output'];
  searchContext: ShopProductSearchContextObject;
  secondaryMedia?: Maybe<ShopProductMediaObject>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  taxons: Array<ShopTaxonObject>;
  thumbnailBase64?: Maybe<Scalars['String']['output']>;
  variants: Array<ShopProductVariantObject>;
  variation: ProductVariation;
}

export interface ShopProductOptionObject {
  __typename?: 'ShopProductOptionObject';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  values: Array<ShopProductOptionValueObject>;
}

export interface ShopProductOptionValueObject {
  __typename?: 'ShopProductOptionValueObject';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  optionId: Scalars['Int']['output'];
}

export interface ShopProductSearchContextObject {
  __typename?: 'ShopProductSearchContextObject';
  objectId: Scalars['String']['output'];
  queryIndex: Scalars['String']['output'];
}

export interface ShopProductSearchItemObject {
  __typename?: 'ShopProductSearchItemObject';
  objectId: Scalars['String']['output'];
  product: ShopProductObject;
  /** @deprecated Renamed to objectId */
  searchResultId: Scalars['String']['output'];
}

export interface ShopProductSearchObject {
  __typename?: 'ShopProductSearchObject';
  items: Array<ShopProductSearchItemObject>;
  queryId?: Maybe<Scalars['String']['output']>;
  queryIndex: Scalars['String']['output'];
  totalItemsCount: Scalars['Int']['output'];
}

export interface ShopProductSearchQuerySuggestionObject {
  __typename?: 'ShopProductSearchQuerySuggestionObject';
  items: Array<ShopProductSearchQuerySuggestionResultObject>;
  totalItemsCount: Scalars['Int']['output'];
}

export interface ShopProductSearchQuerySuggestionResultObject {
  __typename?: 'ShopProductSearchQuerySuggestionResultObject';
  priority?: Maybe<Scalars['Int']['output']>;
  query: Scalars['String']['output'];
}

export enum ShopProductUnavailableReason {
  NoShippingMethod = 'NO_SHIPPING_METHOD',
  NoShippingSlot = 'NO_SHIPPING_SLOT',
  NoSlotCapacity = 'NO_SLOT_CAPACITY',
  NoStock = 'NO_STOCK',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN',
  Unlisted = 'UNLISTED',
  UnlistedRegion = 'UNLISTED_REGION'
}

export interface ShopProductVariantObject {
  __typename?: 'ShopProductVariantObject';
  available: Scalars['Boolean']['output'];
  availableRegions: Array<ShopGeoRegionObject>;
  capAmount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  optionValues: Array<ShopProductVariantOptionValueObject>;
  price: ShopCurrencyObject;
  regions: Array<ShopProductVariantRegionObject>;
  slug: Scalars['String']['output'];
}

export interface ShopProductVariantOptionValueObject {
  __typename?: 'ShopProductVariantOptionValueObject';
  id: Scalars['Int']['output'];
  optionId: Scalars['Int']['output'];
}

export interface ShopProductVariantRegionObject {
  __typename?: 'ShopProductVariantRegionObject';
  isAvailable: Scalars['Boolean']['output'];
  nextAvailableDate?: Maybe<Scalars['DateTimeScalar']['output']>;
  nextAvailableQuantity?: Maybe<Scalars['Int']['output']>;
  region: ShopGeoRegionObject;
  unavailableReason?: Maybe<ShopProductUnavailableReason>;
}

export interface ShopProductWishlistItemObject {
  __typename?: 'ShopProductWishlistItemObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  product: ShopProductObject;
  productId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface ShopProductWishlistItemObjectProductArgs {
  channelCode: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface ShopPromotionObject {
  __typename?: 'ShopPromotionObject';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
}

export interface ShopRedeemCartPaymentInput {
  code: Scalars['String']['input'];
  paymentId: Scalars['Int']['input'];
}

export interface ShopRequestOrderShipmentSlotInput {
  orderId: Scalars['Int']['input'];
  slotId: Scalars['String']['input'];
}

export interface ShopShipmentObject {
  __typename?: 'ShopShipmentObject';
  collection: Scalars['Boolean']['output'];
  costCalculation?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  methodName?: Maybe<Scalars['String']['output']>;
  requestedSlotEndAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requestedSlotId?: Maybe<Scalars['String']['output']>;
  requestedSlotStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  requiredAddressType: AddressType;
  slotEndAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  slotId?: Maybe<Scalars['String']['output']>;
  slotRequired: Scalars['Boolean']['output'];
  slotStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: ShipmentState;
  trackingUrl?: Maybe<Scalars['String']['output']>;
}

export interface ShopShippingMethodObject {
  __typename?: 'ShopShippingMethodObject';
  collectionPoints: Array<ShopCollectionPointObject>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isCollection: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface ShopShippingSlotObject {
  __typename?: 'ShopShippingSlotObject';
  endAt: Scalars['DateTimeScalar']['output'];
  groupingAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
  startAt: Scalars['DateTimeScalar']['output'];
  timezone: Scalars['String']['output'];
  unavailableReason?: Maybe<Scalars['String']['output']>;
}

export interface ShopSortInput {
  column?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
}

export interface ShopSubscriberAgreementDeclarationObject {
  __typename?: 'ShopSubscriberAgreementDeclarationObject';
  agreed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  termsUrls?: Maybe<Scalars['String']['output']>;
}

export interface ShopSubscriberAgreementObject {
  __typename?: 'ShopSubscriberAgreementObject';
  agreed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  termsUrls?: Maybe<Scalars['String']['output']>;
}

export interface ShopSubscriberDeclarationAgreementInput {
  agreed: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
}

export interface ShopSubscriberDeclarationInput {
  agreements: Array<ShopSubscriberDeclarationAgreementInput>;
  locale: Scalars['String']['input'];
  subscriberId: Scalars['Int']['input'];
}

export interface ShopSubscriberObject {
  __typename?: 'ShopSubscriberObject';
  billingAddress?: Maybe<ShopAddressObject>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  price?: Maybe<ShopCurrencyObject>;
  requiredAgreements: Array<ShopSubscriberAgreementObject>;
  requirements?: Maybe<SubscriberRequirementsObject>;
  shippingAddress?: Maybe<ShopAddressObject>;
  state: SubscriberState;
  subscriptionId: Scalars['Int']['output'];
  subscriptionName: Scalars['String']['output'];
  subscriptionSlug: Scalars['String']['output'];
  userPaymentMethod?: Maybe<ShopUserPaymentMethodObject>;
  variantChannel: Scalars['String']['output'];
  variantId: Scalars['Int']['output'];
  variantInterval: SubscriptionRunInterval;
  variantName: Scalars['String']['output'];
}

export interface ShopSubscriptionObject {
  __typename?: 'ShopSubscriptionObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: ShopMetaContentObject;
  name: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  variants: Array<ShopSubscriptionVariantObject>;
}

export interface ShopSubscriptionVariantObject {
  __typename?: 'ShopSubscriptionVariantObject';
  autoRenew: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  interval: SubscriptionRunInterval;
  meta: ShopMetaContentObject;
  name: Scalars['String']['output'];
  price: ShopCurrencyObject;
}

export interface ShopTaxonBadgeObject {
  __typename?: 'ShopTaxonBadgeObject';
  badgeIconUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
}

export interface ShopTaxonObject {
  __typename?: 'ShopTaxonObject';
  ascendents: Array<ShopTaxonObject>;
  badge: Scalars['Boolean']['output'];
  badgeIconUrl?: Maybe<Scalars['String']['output']>;
  channelCode: Scalars['String']['output'];
  children: Array<ShopTaxonObject>;
  contentRef?: Maybe<Scalars['String']['output']>;
  descendentProductCount: Scalars['Int']['output'];
  descendents: Array<ShopTaxonObject>;
  description?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: ShopMetaContentObject;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  path: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  productCount: Scalars['Int']['output'];
  regionId?: Maybe<Scalars['Int']['output']>;
  siblings: Array<ShopTaxonObject>;
  slug: Scalars['String']['output'];
}

export interface ShopUpdateCartAddressInput {
  address?: InputMaybe<AddressInput>;
  assignment: AddressAssignment;
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartCollectionPointInput {
  collectionPointId: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartCouponInput {
  couponCode: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartCustomerInput {
  orderId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopUpdateCartGiftOptionsInput {
  giftMessage?: InputMaybe<Scalars['String']['input']>;
  isGift: Scalars['Boolean']['input'];
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartInstructionsInput {
  instructions: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartItemInput {
  context?: InputMaybe<ShopCartItemContextInput>;
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
}

export interface ShopUpdateCartItemsInput {
  context?: InputMaybe<ShopCartItemContextInput>;
  items: Array<ShopUpdateCartItemsItemInput>;
  orderId: Scalars['Int']['input'];
}

export interface ShopUpdateCartItemsItemInput {
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
}

export interface ShopUpdateCartReferrerInput {
  orderId: Scalars['Int']['input'];
  referrerCode: Scalars['String']['input'];
}

export interface ShopUpdateSubscriberAddressInput {
  address?: InputMaybe<ShopAddressInput>;
  assignment: AddressAssignment;
  subscriberId: Scalars['Int']['input'];
}

export interface ShopUpdateSubscriberPaymentMethodInput {
  subscriberId: Scalars['Int']['input'];
  userPaymentMethodId: Scalars['Int']['input'];
}

export interface ShopUpdateUserAddressInput {
  address: ShopAddressInput;
  addressId: Scalars['Int']['input'];
  setAsDefaultBillingAddress?: Scalars['Boolean']['input'];
  setAsDefaultShippingAddress?: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopUpdateUserInput {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
}

export interface ShopUpdateUserPasswordInput {
  password: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
}

export interface ShopUserAgreementObject {
  __typename?: 'ShopUserAgreementObject';
  agreed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  termsUrls?: Maybe<Scalars['String']['output']>;
}

export interface ShopUserDeclarationAgreementInput {
  agreed: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
}

export interface ShopUserDeclarationInput {
  agreements: Array<ShopUserDeclarationAgreementInput>;
  locale: Scalars['String']['input'];
}

export interface ShopUserObject {
  __typename?: 'ShopUserObject';
  addresses: Array<ShopAddressObject>;
  defaultBillingAddressId?: Maybe<Scalars['Int']['output']>;
  defaultShippingAddressId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  members: Array<ShopMemberObject>;
  paymentMethods: Array<ShopUserPaymentMethodObject>;
  phone?: Maybe<Scalars['String']['output']>;
}

export interface ShopUserPaymentMethodObject {
  __typename?: 'ShopUserPaymentMethodObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  description: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  isExpired: Scalars['Boolean']['output'];
  method: ShopPaymentMethodObject;
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  reference: Scalars['String']['output'];
  registrationId?: Maybe<Scalars['String']['output']>;
  state: UserPaymentMethodState;
}

export interface ShopVoucherObject {
  __typename?: 'ShopVoucherObject';
  balance: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  token: Scalars['String']['output'];
  value: Scalars['Int']['output'];
}

export interface ShopVoucherPaymentBalanceObject {
  __typename?: 'ShopVoucherPaymentBalanceObject';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface ShopWalletObject {
  __typename?: 'ShopWalletObject';
  balance: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  transactions: Array<ShopWalletTransactionObject>;
}

export interface ShopWalletTransactionObject {
  __typename?: 'ShopWalletTransactionObject';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  state: WalletTransactionState;
}

export interface SortInput {
  column: Scalars['String']['input'];
  /** Sort order asc|desc */
  order?: Scalars['String']['input'];
}

export interface StockAllocationIntervalFilterInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  onlyEnabledWarehouses?: InputMaybe<Scalars['Boolean']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  stockItemId?: InputMaybe<Scalars['Int']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface StockAllocationIntervalObject {
  __typename?: 'StockAllocationIntervalObject';
  interval: IntervalObject;
  item: StockItemObject;
  lotQuantities: StockAllocationLotQuantitiesObject;
  orderQuantities: StockAllocationOrderQuantitiesObject;
  warehouse: WarehouseObject;
}

export interface StockAllocationLotQuantitiesObject {
  __typename?: 'StockAllocationLotQuantitiesObject';
  allocated: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  reserved: Scalars['Int']['output'];
}

export interface StockAllocationOrderQuantitiesObject {
  __typename?: 'StockAllocationOrderQuantitiesObject';
  allocated: Scalars['Int']['output'];
  cancelled: Scalars['Int']['output'];
  packed: Scalars['Int']['output'];
  reserved: Scalars['Int']['output'];
  unallocated: Scalars['Int']['output'];
}

export interface StockAvailabilityIntervalFilterInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  stockItemId?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
}

export interface StockAvailabilityIntervalObject {
  __typename?: 'StockAvailabilityIntervalObject';
  availableLots: Array<StockLotObject>;
  availableQuantities: Array<StockItemAvailableQuantityObject>;
  interval: IntervalObject;
  isAvailable: Scalars['Boolean']['output'];
  warehouse: WarehouseObject;
}

export interface StockCategoryFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface StockCategoryObject {
  __typename?: 'StockCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface StockItemAvailableQuantityObject {
  __typename?: 'StockItemAvailableQuantityObject';
  item: StockItemObject;
  quantity?: Maybe<Scalars['Int']['output']>;
  requiredQuantity: Scalars['Int']['output'];
}

export interface StockItemComponentObject {
  __typename?: 'StockItemComponentObject';
  componentItem: StockItemObject;
  componentQuantity: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  parentItem: StockItemObject;
}

export interface StockItemFilterInput {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shippingCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sku?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface StockItemObject {
  __typename?: 'StockItemObject';
  category?: Maybe<StockCategoryObject>;
  components: Array<StockItemComponentObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  length?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nextLots: Array<StockLotObject>;
  schedules: Array<StockScheduleObject>;
  shippingCategories: Array<ShippingCategoryObject>;
  sku: Scalars['String']['output'];
  stockQuantityFixedInterval: Array<StockItemWarehouseQuantityIntervalObject>;
  tags: Array<StockCategoryObject>;
  type: StockType;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<ProductVariantObject>;
  warehouseOptions: Array<StockItemWarehouseOptionObject>;
  warehouses: Array<WarehouseObject>;
  weight?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
}


export interface StockItemObjectStockQuantityFixedIntervalArgs {
  input?: InputMaybe<StockQuantityFixedIntervalInput>;
}

export interface StockItemQuantityIntervalObject {
  __typename?: 'StockItemQuantityIntervalObject';
  allocatedQuantity: Scalars['Int']['output'];
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  interval: IntervalObject;
  reservedQuantity: Scalars['Int']['output'];
}

export interface StockItemWarehouseOptionInput {
  location?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['Int']['input'];
}

export interface StockItemWarehouseOptionObject {
  __typename?: 'StockItemWarehouseOptionObject';
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  stockItem: StockItemObject;
  warehouse: WarehouseObject;
}

export interface StockItemWarehouseQuantityIntervalObject {
  __typename?: 'StockItemWarehouseQuantityIntervalObject';
  intervals: Array<StockItemQuantityIntervalObject>;
  warehouse: WarehouseObject;
}

export interface StockLotObject {
  __typename?: 'StockLotObject';
  allocatedQuantity: Scalars['Int']['output'];
  availableAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  exists: Scalars['Boolean']['output'];
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['String']['output'];
  initialQuantity: Scalars['Int']['output'];
  item: StockItemObject;
  priority: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  releasedQuantity: Scalars['Int']['output'];
  reservedQuantity: Scalars['Int']['output'];
  schedule?: Maybe<StockScheduleObject>;
  tracked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
}

export interface StockLotRefFilterInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  stockItemId?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
}

export interface StockQuantityFixedIntervalInput {
  end?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface StockScheduleCreateInput {
  enabled: Scalars['Boolean']['input'];
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  interval: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  shelfLife?: InputMaybe<Scalars['Duration']['input']>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  stockItemId: Scalars['Int']['input'];
  tracked: Scalars['Boolean']['input'];
  warehouseId: Scalars['Int']['input'];
}

export interface StockScheduleObject {
  __typename?: 'StockScheduleObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['String']['output'];
  item: StockItemObject;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  shelfLife?: Maybe<Scalars['Duration']['output']>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  tracked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  warehouse: WarehouseObject;
}

export interface StockScheduleUpdateEnabledInput {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
}

export interface StockScheduleUpdateInput {
  enabled: Scalars['Boolean']['input'];
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  id: Scalars['Int']['input'];
  interval: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  shelfLife?: InputMaybe<Scalars['Duration']['input']>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  stockItemId: Scalars['Int']['input'];
  tracked: Scalars['Boolean']['input'];
  warehouseId: Scalars['Int']['input'];
}

export enum StockType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export interface StripePublicClientAttributes {
  __typename?: 'StripePublicClientAttributes';
  apiKeyPublic?: Maybe<Scalars['String']['output']>;
}

export interface SubscriberAgreementDeclarationObject {
  __typename?: 'SubscriberAgreementDeclarationObject';
  agreed: Scalars['Boolean']['output'];
  agreedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  agreement: AgreementObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  subscriber: SubscriberObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriberFilterInput {
  createdBetween?: InputMaybe<DateRangeFilterInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  intervalState?: InputMaybe<SubscriberInterval>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<SubscriberState>;
  subscriptionIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  subscriptionVariantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export enum SubscriberInterval {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export enum SubscriberNotificationType {
  PaymentRequest = 'PAYMENT_REQUEST',
  SignUp = 'SIGN_UP'
}

export interface SubscriberObject {
  __typename?: 'SubscriberObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  agreements?: Maybe<Array<SubscriberAgreementDeclarationObject>>;
  billingAddress?: Maybe<AddressObject>;
  createdAt: Scalars['DateTimeScalar']['output'];
  endAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  firstActivatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  intervalState: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  members: Array<MemberObject>;
  pausedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  shippingAddress?: Maybe<AddressObject>;
  startAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  state: SubscriberState;
  tags: Array<SubscriberTagObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
  userPaymentMethod?: Maybe<UserPaymentMethodObject>;
  variant: SubscriptionVariantObject;
}

export interface SubscriberRequirementObject {
  __typename?: 'SubscriberRequirementObject';
  code: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export interface SubscriberRequirementsObject {
  __typename?: 'SubscriberRequirementsObject';
  canSubscribe: Scalars['Boolean']['output'];
  requirements: Array<SubscriberRequirementObject>;
}

export enum SubscriberState {
  Active = 'ACTIVE',
  Cart = 'CART',
  Paused = 'PAUSED'
}

export interface SubscriberTagFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriberTagInput {
  subscriberId: Scalars['Int']['input'];
  tagIds: Array<Scalars['Int']['input']>;
}

export interface SubscriberTagObject {
  __typename?: 'SubscriberTagObject';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionEntryFilterInput {
  errorDescription?: InputMaybe<Scalars['String']['input']>;
  hasOrder?: InputMaybe<Scalars['Boolean']['input']>;
  paymentState?: InputMaybe<Array<OrderPaymentState>>;
  runDate?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  runIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shippingAddressGeoRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  state?: InputMaybe<Array<OrderState>>;
  subscriptionVariantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export enum SubscriptionEntryNotificationType {
  PaymentRequest = 'PAYMENT_REQUEST'
}

export interface SubscriptionEntryObject {
  __typename?: 'SubscriptionEntryObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  notifications: Array<NotificationLogObject>;
  order?: Maybe<OrderObject>;
  run: SubscriptionRunObject;
  subscriber: SubscriberObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriptionMembershipBenefitInput {
  duration?: InputMaybe<Scalars['Duration']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  membershipId: Scalars['Int']['input'];
  variantId: Scalars['Int']['input'];
}

export interface SubscriptionMembershipBenefitObject {
  __typename?: 'SubscriptionMembershipBenefitObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  duration?: Maybe<Scalars['Duration']['output']>;
  id: Scalars['Int']['output'];
  membership: MembershipObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: SubscriptionVariantObject;
}

export interface SubscriptionObject {
  __typename?: 'SubscriptionObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  runCount: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
  translations: Array<SubscriptionTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  variants: Array<SubscriptionVariantObject>;
}


export interface SubscriptionObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriptionRunFilterInput {
  subscriptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  subscriptionVariantId?: InputMaybe<Scalars['Int']['input']>;
  subscriptionVariantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface SubscriptionRunGenerateEntriesResult {
  __typename?: 'SubscriptionRunGenerateEntriesResult';
  entriesCreated: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
}

export interface SubscriptionRunInput {
  runEnd?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  runStart?: InputMaybe<Scalars['DateTimeScalar']['input']>;
}

export enum SubscriptionRunInterval {
  Bimonthly = 'BIMONTHLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export interface SubscriptionRunMoneyObject {
  __typename?: 'SubscriptionRunMoneyObject';
  currency?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
}

export interface SubscriptionRunObject {
  __typename?: 'SubscriptionRunObject';
  cancelledOrderCount: Scalars['Int']['output'];
  completedOrderCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  entryCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  jobAction?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['String']['output']>;
  jobStartAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  name: Scalars['String']['output'];
  orderCount: Scalars['Int']['output'];
  paidOrderCount: Scalars['Int']['output'];
  pendingOrderCount: Scalars['Int']['output'];
  runEnd: Scalars['DateTimeScalar']['output'];
  runStart: Scalars['DateTimeScalar']['output'];
  salesTotals: Array<SubscriptionRunMoneyObject>;
  subscriberCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  variant: SubscriptionVariantObject;
}

export interface SubscriptionRunProcessEntriesResult {
  __typename?: 'SubscriptionRunProcessEntriesResult';
  entryCount: Scalars['Int']['output'];
  ordersCreated: Scalars['Int']['output'];
}

export interface SubscriptionRunProcessPaymentsResult {
  __typename?: 'SubscriptionRunProcessPaymentsResult';
  entriesProcessed: Scalars['Int']['output'];
  entryCount: Scalars['Int']['output'];
  paymentsCompleted: Scalars['Int']['output'];
  paymentsFailed: Scalars['Int']['output'];
}

export interface SubscriptionTranslationInput {
  contentRef?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  termsUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriptionTranslationObject {
  __typename?: 'SubscriptionTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  termsUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface SubscriptionVariantCurrencyObject {
  __typename?: 'SubscriptionVariantCurrencyObject';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface SubscriptionVariantFilterInput {
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  intervals?: InputMaybe<Array<SubscriptionRunInterval>>;
  search?: InputMaybe<Scalars['String']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export interface SubscriptionVariantInput {
  agreementIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  autoRenew?: InputMaybe<Scalars['Boolean']['input']>;
  channelId: Scalars['Int']['input'];
  duration?: InputMaybe<Scalars['Duration']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interval: SubscriptionRunInterval;
  membershipBenefits?: InputMaybe<Array<SubscriptionMembershipBenefitInput>>;
  name: Scalars['String']['input'];
  subscriptionId: Scalars['Int']['input'];
  subscriptionVariantProductQuantities?: InputMaybe<Array<SubscriptionVariantProductQuantityInput>>;
  translations?: InputMaybe<Array<SubscriptionVariantTranslationInput>>;
}

export interface SubscriptionVariantObject {
  __typename?: 'SubscriptionVariantObject';
  agreements?: Maybe<Array<AgreementObject>>;
  autoRenew: Scalars['Boolean']['output'];
  channel: ChannelObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  duration?: Maybe<Scalars['Duration']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  immediateProcess: Scalars['Boolean']['output'];
  interval: SubscriptionRunInterval;
  membershipBenefits: Array<SubscriptionMembershipBenefitObject>;
  name: Scalars['String']['output'];
  price: SubscriptionVariantCurrencyObject;
  productQuantities: Array<SubscriptionVariantProductQuantityObject>;
  runCount: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
  subscription: SubscriptionObject;
  translations: Array<SubscriptionVariantTranslationObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface SubscriptionVariantObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriptionVariantProductQuantityFilterInput {
  productVariantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  subscriptionVariantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
}

export interface SubscriptionVariantProductQuantityInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  subscriptionVariantId: Scalars['Int']['input'];
}

export interface SubscriptionVariantProductQuantityObject {
  __typename?: 'SubscriptionVariantProductQuantityObject';
  id: Scalars['Int']['output'];
  productVariant: ProductVariantObject;
  quantity: Scalars['Int']['output'];
  subscriptionVariant: SubscriptionVariantObject;
}

export interface SubscriptionVariantSmsInput {
  message?: InputMaybe<Scalars['String']['input']>;
  subscriptionVariantSmsType: SubscriptionVariantSmsType;
}

export interface SubscriptionVariantSmsObject {
  __typename?: 'SubscriptionVariantSmsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  translation: SubscriptionVariantTranslationObject;
  type: SubscriptionVariantSmsType;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum SubscriptionVariantSmsType {
  Custom = 'CUSTOM',
  Deduction = 'DEDUCTION',
  Delivery = 'DELIVERY',
  Paid = 'PAID',
  PaymentProcessing = 'PAYMENT_PROCESSING',
  Unpaid = 'UNPAID'
}

export interface SubscriptionVariantTranslationInput {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subscriptionVariantSmses?: InputMaybe<Array<SubscriptionVariantSmsInput>>;
}

export interface SubscriptionVariantTranslationObject {
  __typename?: 'SubscriptionVariantTranslationObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta: MetaContentObject;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  smses?: Maybe<Array<SubscriptionVariantSmsObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum TargetAuthorizationType {
  Anonymous = 'ANONYMOUS',
  Any = 'ANY',
  Authenticated = 'AUTHENTICATED'
}

export interface TaxCategoryFilterInput {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface TaxCategoryObject {
  __typename?: 'TaxCategoryObject';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxRateFilterInput {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface TaxRateObject {
  __typename?: 'TaxRateObject';
  amount: Scalars['Int']['output'];
  category: TaxCategoryObject;
  channel: ChannelObject;
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  inclusive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  province?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxonAttributeValueInput {
  attributeId: Scalars['Float']['input'];
  locale: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
}

export interface TaxonAttributeValueObject {
  __typename?: 'TaxonAttributeValueObject';
  attribute: ProductAttributeObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  taxon: TaxonObject;
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['JSON']['output'];
}

export interface TaxonFeedOptionsInput {
  customLabel0?: InputMaybe<Scalars['String']['input']>;
  customLabel1?: InputMaybe<Scalars['String']['input']>;
  customLabel2?: InputMaybe<Scalars['String']['input']>;
  googleProductCategory?: InputMaybe<Scalars['String']['input']>;
}

export interface TaxonFeedOptionsObject {
  __typename?: 'TaxonFeedOptionsObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  customLabel0?: Maybe<Scalars['String']['output']>;
  customLabel1?: Maybe<Scalars['String']['output']>;
  customLabel2?: Maybe<Scalars['String']['output']>;
  googleProductCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxonFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  parentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface TaxonMediaInput {
  alt?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  placement?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  quality?: InputMaybe<Scalars['Float']['input']>;
  type: MediaType;
  url: Scalars['String']['input'];
  variantId?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface TaxonMediaObject {
  __typename?: 'TaxonMediaObject';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  quality?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface TaxonObject {
  __typename?: 'TaxonObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  ascendants: Array<TaxonObject>;
  attributeValues: Array<TaxonAttributeValueObject>;
  attributes: Array<ProductAttributeObject>;
  badge: Scalars['Boolean']['output'];
  badgeIconUrl?: Maybe<Scalars['String']['output']>;
  children: Array<TaxonObject>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  descendents: Array<TaxonObject>;
  descendentsMainProductsCount: Scalars['Int']['output'];
  descendentsProductsCount: Scalars['Int']['output'];
  feedOptions?: Maybe<TaxonFeedOptionsObject>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mainMedia?: Maybe<TaxonMediaObject>;
  mainProducts: Array<ProductObject>;
  mainProductsCount: Scalars['Int']['output'];
  media: Array<TaxonMediaObject>;
  name: Scalars['String']['output'];
  parent?: Maybe<TaxonObject>;
  parentId?: Maybe<Scalars['Int']['output']>;
  path: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  productPositions: Array<ProductPositionObject>;
  productsCount: Scalars['Int']['output'];
  translations: Array<TaxonTranslationObject>;
  translationsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}


export interface TaxonObjectAttributeValuesArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}


export interface TaxonObjectTranslationsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface TaxonProductFilterInput {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  publishStates?: InputMaybe<Array<ProductPublishState>>;
  search?: InputMaybe<Scalars['String']['input']>;
  taxon?: InputMaybe<ProductTaxonFilterInput>;
}

export interface TaxonTranslationInput {
  contentRef?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  meta?: InputMaybe<MetaContentInput>;
  name: Scalars['String']['input'];
}

export interface TaxonTranslationObject {
  __typename?: 'TaxonTranslationObject';
  contentRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  meta?: Maybe<MetaContentObject>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface TaxonTreeObject {
  __typename?: 'TaxonTreeObject';
  branch: TaxonObject;
  children: Array<TaxonTreeObject>;
}

export interface TransitionShipmentInput {
  id: Scalars['Int']['input'];
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  transition: ShipmentStateTransition;
}

export interface UpdateAdInput {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  id: Scalars['Int']['input'];
  media?: InputMaybe<Array<AdMediaInput>>;
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  targetAuthorizationType?: InputMaybe<TargetAuthorizationType>;
  targetChannelId?: InputMaybe<Scalars['Int']['input']>;
  targetChildrenTaxons?: InputMaybe<Scalars['Boolean']['input']>;
  targetRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  targetTaxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  url: Scalars['String']['input'];
  urlTarget: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
}

export interface UpdateAgreementInput {
  channelIds: Array<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  translations: Array<AgreementTranslationInput>;
}

export interface UpdateAuthRoleInput {
  allChannels?: InputMaybe<Scalars['Boolean']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface UpdateBulkOrderInput {
  customerUserId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}

export interface UpdateCartAddressInput {
  address?: InputMaybe<AddressInput>;
  assignment: AddressAssignment;
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartCloneAddressInput {
  addressId: Scalars['Int']['input'];
  assignment: AddressAssignment;
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartCollectionPointInput {
  collectionPointId: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartCouponCodeInput {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartCustomerInput {
  orderId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateCartGiftOptionsInput {
  giftMessage?: InputMaybe<Scalars['String']['input']>;
  isGift: Scalars['Boolean']['input'];
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartInstructionsInput {
  instructions?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
}

export interface UpdateCartItemInput {
  adjustmentAmount?: InputMaybe<Scalars['Int']['input']>;
  adjustmentLabel?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
}

export interface UpdateCartReferrerCodeInput {
  orderId: Scalars['Int']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateChannelInput {
  color?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultCollectionPointId?: InputMaybe<Scalars['Int']['input']>;
  defaultCountry?: InputMaybe<Scalars['String']['input']>;
  defaultLocale?: InputMaybe<Scalars['String']['input']>;
  defaultTaxCategoryId?: InputMaybe<Scalars['Int']['input']>;
  defaultWarehouseId?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  rootTaxonId?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<ChannelTranslationInput>>;
}

export interface UpdateCollectionPointInput {
  address?: InputMaybe<AddressInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  methodId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  providerConfig?: InputMaybe<Scalars['JSON']['input']>;
}

export interface UpdateGeoRegionDefinitionInput {
  boundary?: InputMaybe<PolygonInput>;
  center?: InputMaybe<PointInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  include: Scalars['Boolean']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateGeoRegionInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  primaryChannelId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateMemberInput {
  id: Scalars['Int']['input'];
  state: MemberState;
}

export interface UpdateMembershipInput {
  activationUrl?: InputMaybe<Scalars['String']['input']>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Int']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  taxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  translations?: InputMaybe<Array<MembershipTranslationInput>>;
}

export interface UpdateOrderAddressInput {
  address?: InputMaybe<AddressInput>;
  assignment: AddressAssignment;
  orderId: Scalars['Int']['input'];
}

export interface UpdateOrderCustomerInput {
  orderId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateOrderGiftOptionsInput {
  giftMessage?: InputMaybe<Scalars['String']['input']>;
  isGift: Scalars['Boolean']['input'];
  orderId: Scalars['Int']['input'];
}

export interface UpdateOrderIncidentCategoryInput {
  allowedOrderStates?: InputMaybe<Array<OrderState>>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  itemActionAllowed?: InputMaybe<Array<OrderIncidentItemAction>>;
  itemActionRequired?: InputMaybe<Array<OrderIncidentItemAction>>;
  name?: InputMaybe<Scalars['String']['input']>;
  otherItemActionDefault?: InputMaybe<Array<OrderIncidentItemAction>>;
  requireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  steps?: InputMaybe<Array<Scalars['String']['input']>>;
  subcategories: Array<OrderIncidentSubcategoryInput>;
}

export interface UpdateOrderIncidentInput {
  approverUserId?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  items?: InputMaybe<Array<OrderIncidentItemInput>>;
  subcategoryId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateOrderInstructionsInput {
  instructions?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
}

export interface UpdateOrderItemInput {
  adjustmentAmount?: InputMaybe<Scalars['Int']['input']>;
  adjustmentLabel?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  orderId: Scalars['Int']['input'];
  productVariantId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
}

export interface UpdateOrderShipmentInput {
  orderId: Scalars['Int']['input'];
  serviceOption?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateOrderTagInput {
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateParcelOptionInput {
  dimensions?: InputMaybe<DimensionsInput>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdatePaymentMethodInput {
  channelCodes: Array<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  providerConfig: Scalars['JSON']['input'];
}

export interface UpdateProductAssociationInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  recommendationModel?: InputMaybe<Scalars['String']['input']>;
  sourceMainTaxonEnable?: InputMaybe<Scalars['Boolean']['input']>;
  sourceTaxonId?: InputMaybe<Scalars['Int']['input']>;
  targetMainTaxonEnable?: InputMaybe<Scalars['Boolean']['input']>;
  targetTaxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  targets?: InputMaybe<Array<ProductAssociationTarget>>;
  testCase?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  translations?: InputMaybe<Array<ProductAssociationTranslationInput>>;
}

export interface UpdateProductAttributeInput {
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<ProductAttributeTranslationInput>>;
  type?: InputMaybe<ProductAttributeType>;
}

export interface UpdateProductInput {
  attributeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  attributeValues?: InputMaybe<Array<ProductAttributeValueInput>>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  mainMediaUrl?: InputMaybe<Scalars['String']['input']>;
  mainTaxonId?: InputMaybe<Scalars['Int']['input']>;
  media?: InputMaybe<Array<ProductMediaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  position?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  publishDate?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  secondaryMediaUrl?: InputMaybe<Scalars['String']['input']>;
  taxonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  translations?: InputMaybe<Array<ProductTranslationInput>>;
  unpublish?: InputMaybe<Scalars['Boolean']['input']>;
  variants?: InputMaybe<Array<ProductVariantInput>>;
  variation?: InputMaybe<ProductVariation>;
}

export interface UpdateProductKeywordInput {
  keywords: Array<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  remove?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateProductOptionInput {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<ProductOptionTranslationInput>>;
  values?: InputMaybe<Array<ProductOptionValueInput>>;
}

export interface UpdateProductRegionsInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productIds: Array<Scalars['Int']['input']>;
  regionIds: Array<Scalars['Int']['input']>;
}

export interface UpdatePromotionInput {
  actions: Array<PromotionActionInput>;
  adjustmentLabel?: InputMaybe<Scalars['String']['input']>;
  channelIds: Array<Scalars['Int']['input']>;
  couponBulkQty?: InputMaybe<Scalars['Int']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  couponType: CouponType;
  couponUsageLimit?: InputMaybe<Scalars['Int']['input']>;
  customerUsageLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  exclusive: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  ruleExplanation?: InputMaybe<Scalars['String']['input']>;
  rules: Array<PromotionRuleInput>;
  startsAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateShipmentParcelInput {
  dimensions?: InputMaybe<DimensionsInput>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parcelOptionId?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateShipmentServiceOptionInput {
  id: Scalars['Int']['input'];
  serviceOption?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateShippingCategoryInput {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateShippingMethodInput {
  categoryComparison?: InputMaybe<ShippingCategoryComparison>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  collectionRequired: Scalars['Boolean']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  costCalculator: Scalars['String']['input'];
  costCalculatorConfig?: InputMaybe<Scalars['JSON']['input']>;
  costProductVariantId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  groupDestination: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  labelGenerator: Scalars['String']['input'];
  labelGeneratorConfig?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  provider: Scalars['String']['input'];
  providerConfig?: InputMaybe<Scalars['JSON']['input']>;
  quoteValidityDuration?: InputMaybe<Scalars['Duration']['input']>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  slotRequired: Scalars['Boolean']['input'];
  stockType?: InputMaybe<StockType>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['Int']['input'];
}

export interface UpdateShippingScheduleInput {
  capacity: Scalars['Int']['input'];
  daysOfWeek?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Duration']['input'];
  enabled: Scalars['Boolean']['input'];
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  id: Scalars['Int']['input'];
  lagTime?: InputMaybe<Scalars['Duration']['input']>;
  leadTime?: InputMaybe<Scalars['Duration']['input']>;
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  startTime: Scalars['String']['input'];
}

export interface UpdateShippingScheduleSlotsInput {
  id: Scalars['Int']['input'];
}

export interface UpdateShippingSlotInput {
  capacity: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  endAt: Scalars['DateTimeScalar']['input'];
  id: Scalars['String']['input'];
  lagTime?: InputMaybe<Scalars['Duration']['input']>;
  leadTime?: InputMaybe<Scalars['Duration']['input']>;
  priority?: Scalars['Int']['input'];
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startAt: Scalars['DateTimeScalar']['input'];
}

export interface UpdateStockCategoryInput {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateStockItemCategoryInput {
  id: Scalars['Int']['input'];
  stockCategoryId: Scalars['Int']['input'];
}

export interface UpdateStockItemComponentInput {
  componentQuantity: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
}

export interface UpdateStockItemInput {
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  length?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  productVariantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shippingCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sku?: InputMaybe<Scalars['String']['input']>;
  stockCategoryId: Scalars['Int']['input'];
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<StockType>;
  unit?: InputMaybe<Scalars['String']['input']>;
  warehouseOptions?: InputMaybe<Array<StockItemWarehouseOptionInput>>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateStockItemTagsInput {
  id: Scalars['Int']['input'];
  mutation?: Scalars['String']['input'];
  stockCategoryIds: Array<Scalars['Int']['input']>;
}

export interface UpdateStockLotInput {
  availableAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  id: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  tracked: Scalars['Boolean']['input'];
}

export interface UpdateSubscriberAddressInput {
  address?: InputMaybe<AddressInput>;
  assignment: AddressAssignment;
  subscriberId: Scalars['Int']['input'];
}

export interface UpdateSubscriberInput {
  billingAddressId?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  id: Scalars['Int']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['Int']['input']>;
  startAt?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  state?: InputMaybe<SubscriberState>;
  subscriptionVariantId?: InputMaybe<Scalars['Int']['input']>;
  userPaymentMethodId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateSubscriberTagInput {
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSubscriptionInput {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  subscriptionVariants?: InputMaybe<Array<SubscriptionVariantInput>>;
  translations?: InputMaybe<Array<SubscriptionTranslationInput>>;
}

export interface UpdateTaxCategoryInput {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateTaxRateInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  channelId?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  inclusive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateTaxonInput {
  attributeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  attributeValues?: InputMaybe<Array<TaxonAttributeValueInput>>;
  badge?: InputMaybe<Scalars['Boolean']['input']>;
  badgeIconUrl?: InputMaybe<Scalars['String']['input']>;
  feedOptions?: InputMaybe<TaxonFeedOptionsInput>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  mainMediaUrl?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<TaxonMediaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<TaxonTranslationInput>>;
}

export interface UpdateUserAddressInput {
  address?: InputMaybe<AddressInput>;
  addressId: Scalars['Int']['input'];
  setAsDefaultBillingAddress?: Scalars['Boolean']['input'];
  setAsDefaultShippingAddress?: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
}

export interface UpdateUserInput {
  birthday?: InputMaybe<Scalars['DateTimeScalar']['input']>;
  defaultChannelId?: InputMaybe<Scalars['Float']['input']>;
  defaultLocale?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserRolesInput {
  id: Scalars['Float']['input'];
  roleIds: Array<Scalars['Float']['input']>;
}

export interface UpdateVoucherProgramInput {
  currency: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  productVariantIds: Array<Scalars['Int']['input']>;
  value: Scalars['Int']['input'];
}

export interface UpdateWarehouseInput {
  address?: InputMaybe<AddressInput>;
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
}

export interface UserAgreementDeclarationObject {
  __typename?: 'UserAgreementDeclarationObject';
  agreed: Scalars['Boolean']['output'];
  agreedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  agreement: AgreementObject;
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
}

export interface UserFilterInput {
  agreementIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  hasRole?: InputMaybe<Scalars['Boolean']['input']>;
  membershipIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  subscriptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface UserObject {
  __typename?: 'UserObject';
  addresses: Array<AddressObject>;
  agreements?: Maybe<Array<UserAgreementDeclarationObject>>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  birthday?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  defaultBillingAddress?: Maybe<AddressObject>;
  defaultChannel?: Maybe<ChannelObject>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  defaultShippingAddress?: Maybe<AddressObject>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  members?: Maybe<Array<MemberObject>>;
  orderCount: Scalars['Int']['output'];
  orderSalesStats: Array<OrderSalesStatsObject>;
  orderStateCounts: Array<OrderStateCountsObject>;
  phone?: Maybe<Scalars['String']['output']>;
  roles: Array<AuthRoleObject>;
  subscribers?: Maybe<Array<SubscriberObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export interface UserPaymentMethodFilterInput {
  expired?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface UserPaymentMethodObject {
  __typename?: 'UserPaymentMethodObject';
  activatedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  description: Scalars['String']['output'];
  errorDescription?: Maybe<Scalars['String']['output']>;
  expired: Scalars['Boolean']['output'];
  expiresAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  method: PaymentMethodObject;
  reference: Scalars['String']['output'];
  reusable: Scalars['Boolean']['output'];
  state: UserPaymentMethodState;
  subscribers?: Maybe<Array<SubscriberObject>>;
  updatedAt: Scalars['DateTimeScalar']['output'];
}

export enum UserPaymentMethodState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  New = 'NEW',
  Processing = 'PROCESSING'
}

export interface VariableDeleteObject {
  __typename?: 'VariableDeleteObject';
  key: Scalars['String']['output'];
}

export interface VariableObject {
  __typename?: 'VariableObject';
  createdAt: Scalars['DateTimeScalar']['output'];
  id: Scalars['Float']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['JSON']['output']>;
}

export interface VoucherBalanceObject {
  __typename?: 'VoucherBalanceObject';
  balance: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface VoucherFilterInput {
  createdAt?: InputMaybe<DateRangeFilterInput>;
  issuingUserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  relativeCreatedAt?: InputMaybe<RelativeDateType>;
  search?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface VoucherObject {
  __typename?: 'VoucherObject';
  balance: VoucherBalanceObject;
  cancelled: Scalars['Boolean']['output'];
  cancelledAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  cancelledReason?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  issuingUser?: Maybe<UserObject>;
  orderId?: Maybe<Scalars['Int']['output']>;
  orderItemUnit?: Maybe<OrderItemUnitObject>;
  orderNumber: Scalars['String']['output'];
  program: VoucherProgramObject;
  reason?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
  value: Scalars['Int']['output'];
}

export interface VoucherPaymentBalanceObject {
  __typename?: 'VoucherPaymentBalanceObject';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface VoucherProgramFilterInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface VoucherProgramObject {
  __typename?: 'VoucherProgramObject';
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productVariants: Array<ProductVariantObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  value: Scalars['Int']['output'];
  voucherProviderId: Scalars['String']['output'];
}

export interface VoucherProviderObject {
  __typename?: 'VoucherProviderObject';
  currencies: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface WalletFilterInput {
  currencies?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  walletTypes?: InputMaybe<Array<WalletType>>;
}

export interface WalletObject {
  __typename?: 'WalletObject';
  balance: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  transactions: Array<WalletTransactionObject>;
  updatedAt: Scalars['DateTimeScalar']['output'];
  user: UserObject;
  walletType: WalletType;
}

export interface WalletTransactionFilterInput {
  walletIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface WalletTransactionObject {
  __typename?: 'WalletTransactionObject';
  amount: Scalars['Int']['output'];
  balance: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<OrderObject>;
  state: WalletTransactionState;
  updatedAt: Scalars['DateTimeScalar']['output'];
  wallet: WalletObject;
}

export enum WalletTransactionState {
  Credited = 'CREDITED',
  Debited = 'DEBITED',
  New = 'NEW'
}

export enum WalletType {
  Shop = 'SHOP',
  Temp = 'TEMP'
}

export interface WarehouseFilterInput {
  channelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdAt?: InputMaybe<DateRangeFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateRangeFilterInput>;
}

export interface WarehouseObject {
  __typename?: 'WarehouseObject';
  address?: Maybe<AddressObject>;
  archivedAt?: Maybe<Scalars['DateTimeScalar']['output']>;
  channels: Array<ChannelObject>;
  code: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeScalar']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lots: Array<StockLotObject>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeScalar']['output'];
}
